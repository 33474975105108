import { ReactNode, useEffect, useState } from 'react'
import SideBar from '../components/molecules/side-bar'
import { Mobile } from '../components/molecules/side-bar/mobile'
import { useMutation } from 'react-query'
import { getUser } from '../services/ApiClient'
import { useDispatch } from 'react-redux'
import { setUser } from '../redux/actions/LoginActions'

interface ILayoutProps {
  children: ReactNode
}

// eslint-disable-next-line react/prop-types
export const Layout: React.FC<ILayoutProps> = ({ children }) => {

  const [isMobile, setIsMobile] = useState(false)
  const dispatch = useDispatch()

  const getUserMutation = useMutation('getUser', () => getUser(), {
    onSuccess: (data) => {
      dispatch(setUser(data))
    },
  })

  useEffect(() => {
    getUserMutation.mutate()
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000) // Adjust the breakpoint as needed
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }

  }, [])



  return (
    <>
      <div className="w-full p-0 b-0 flex flex-row flex-wrap sticky">
        {!isMobile && (
          <aside className="h-screen sticky top-0 bg-white z-10">
            <SideBar />
          </aside>
        )}
        <main className="flex-1 bg-gray-50 min-h-screen md:max-h-none">{children}</main>
      </div>
      {isMobile && <Mobile />}
    </>
  )
}
