import { IInitialValues } from '../../types'

export const InitialValues: IInitialValues = {
  sport_type: '',
  venue_name: '',
  number_of_participants: 0,
  activity_date: new Date(),
  start_time: '09:00',
  end_time: '23:00',
  total_price: 0,
  court_booked: false,
  invites_only: false,
  pay_and_join: false,
  byoe: false,
  image_url: 'dummy image url',
  bank_full_name: '',
  sort_code: '',
  account_number: '',
}
