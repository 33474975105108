import { Button, Label, Spinner } from 'flowbite-react'
import { useNavigate, useParams } from 'react-router-dom'
import { getActivity, joinActivityRequest } from '../../services/ApiClient'
import { useMutation, useQuery } from 'react-query'
import { formatDate, formatTime, getSportIcon } from '../../lib/utils'
import { FaLocationDot, FaCalendar } from 'react-icons/fa6'
import { IoIosTime } from 'react-icons/io'
import { SiGooglemaps } from 'react-icons/si'

import {
  IoChatbubbleEllipsesOutline,
  IoPersonCircleSharp,
} from 'react-icons/io5'
import { FaPeopleGroup } from 'react-icons/fa6'
import { GiTennisCourt } from 'react-icons/gi'
import { AiFillPoundCircle } from 'react-icons/ai'
import { GiSonicShoes } from 'react-icons/gi'
import { customStyles } from '../../ui/styles'
import { ActivityRequestTable } from '../../components/molecules/ActivityRequestTable'
import { useSelector } from 'react-redux'
import { selectUser } from '../../redux/selectors'
import { PayNowButton } from '../../components/atoms/PayNowButton'
import { toast } from 'react-hot-toast'
import { IAxiosError } from '../../types'
export const ActivityDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data: activity, isLoading } = useQuery('getActivity', () =>
    getActivity(Number(id)),
  )
  const currentUser = useSelector(selectUser)

  const alreadyParticitpant = activity?.participants
    .map((_) => _.user_id)
    .includes(currentUser.id)

  const joinActivityMutation = useMutation(
    () => joinActivityRequest({ id: Number(id) }),
    {
      onSuccess: (data) => {
        const message = activity?.pay_and_join
          ? 'Redirecting to payment page'
          : 'Request Sent to the host successfully'
        toast.success(message)
        if (activity?.pay_and_join) {
          window.location.href = data.payment_link
        }
      },
      onError: (error: IAxiosError) => {
        toast.error(error.response.data.message)
      },
    },
  )

  const handleJoinClick = () => {
    joinActivityMutation.mutate()
  }

  const handleGroupChat = () => {
    navigate(`/messages/activity_chat/${id}`)
  }

  if (isLoading) {
    return <Spinner />
  }

  if (!activity) {
    return null
  }

  const handleMaps = () => {
    window.open(activity.google_map_url, '_blank')
  }

  return (
    <section className="md:w-5/6 mx-auto">
      <div className="bg-white p-8 md:mt-5 rounded-md ">
        <h3 className="font-bold text-2xl mb-8 text-center text-gray-800">
          {activity.user_first_name}'s Activity
        </h3>
        <div className="grid gap-4 md:gap-0 md:flex">
          <div className="flex flex-col gap-3 flex-1 justify-center">
            <div className="flex flex-row items-center gap-4 justify-between md:justify-normal">
              <div className="flex flex-row items-center">
                {getSportIcon(activity.sport_type)}
                <Label className="font-bold text-md text-green-800">
                  Sport Type:
                </Label>
              </div>
              <div className="text-gray-600 font-bold capitalize">
                {activity.sport_type}
              </div>
            </div>

            <div className="flex flex-row items-center gap-4 justify-between md:justify-normal">
              <div className="flex flex-row items-center">
                <FaLocationDot
                  className="mr-2"
                  style={customStyles}
                  size={20}
                />
                <Label className="font-bold text-md text-green-800">
                  Venue Name:
                </Label>
              </div>
              <a href={activity.google_map_url} target="blank" className='underline text-blue-600 md:text-center'>
                <div className= "font-bold overflow-hidden text-sm text-blue-600">
                  {activity.venue_name?.split(',')[0]}
                </div>
              </a>
            </div>

            <div className="flex flex-row items-center gap-4 justify-between md:justify-normal">
              <div className="flex flex-row items-center">
                <FaCalendar className="mr-2" style={customStyles} size={20} />
                <Label className="font-bold text-md text-green-800">
                  Activity Date:
                </Label>
              </div>
              <div className="text-gray-600 font-bold">
                {formatDate(activity?.activity_date)}
              </div>
            </div>

            <div className="flex flex-row items-center gap-4 justify-between md:justify-normal">
              <div className="flex flex-row items-center">
                <IoIosTime className="mr-2" style={customStyles} size={20} />
                <Label className="font-bold text-md text-green-800">
                  Start Time:
                </Label>
              </div>
              <div className="text-gray-600 font-bold">
                {formatTime(activity?.start_time, activity.end_time)}
              </div>
            </div>

            <div className="flex flex-row items-center gap-4 justify-between md:justify-normal">
              <div className="flex flex-row items-center">
                <IoPersonCircleSharp
                  className="mr-2"
                  style={customStyles}
                  size={20}
                />
                <Label className="font-bold text-md text-green-800">
                  Hosted By:
                </Label>
              </div>
              <div className="text-gray-600 font-bold">
                {activity.user_first_name}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3 flex-1 justify-center">
            <div className="flex flex-row items-center gap-4 justify-between md:justify-normal">
              <div className="flex flex-row items-center">
                <FaPeopleGroup
                  className="mr-2"
                  style={customStyles}
                  size={20}
                />
                <Label className="font-bold text-md text-green-800">
                  {' '}
                  Number of participants
                </Label>
              </div>

              <div className="text-gray-600 font-bold">
                {activity.number_of_participants}
              </div>
            </div>

            <div className="flex flex-row items-center gap-4 justify-between md:justify-normal">
              <div className="flex flex-row items-center">
                <GiTennisCourt
                  className="mr-2"
                  style={customStyles}
                  size={20}
                />
                <Label className="font-bold text-md text-green-800">
                  Is court booked?{' '}
                </Label>
              </div>
              <div className="text-gray-600 font-bold">
                {activity.court_booked ? 'Yes' : 'No'}
              </div>
            </div>

            <div className="flex flex-row items-center gap-4 justify-between md:justify-normal">
              <div className="flex flex-row items-center">
                <AiFillPoundCircle
                  className="mr-2"
                  style={customStyles}
                  size={20}
                />
                <Label className="font-bold text-md text-green-800">
                  Total price{' '}
                </Label>
              </div>
              <div className="text-gray-600 font-bold">
                {activity.total_price}
              </div>
            </div>

            <div className="flex flex-row items-center gap-4 justify-between md:justify-normal">
              <div className="flex flex-row items-center">
                <GiSonicShoes className="mr-2" style={customStyles} size={20} />
                <Label className="font-bold text-md text-green-800">
                  Bring your own equipment{' '}
                </Label>
              </div>
              <div className="text-gray-600 font-bold">
                {activity.byoe ? 'Yes' : 'No'}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 text-center mx-auto">
          <Button className=' bg-gradient-to-br from-gray-800 to-green-800' onClick={handleMaps}>
            <SiGooglemaps className="mr-2" size={20} />
            Get Directions
          </Button>
        </div>
      </div>

      <div className="mt-8">
        <div className="mx-auto bg-white p-8 rounded-md mt-8 ">
          <h3 className="text-xl font-bold mb-4 text-center md:text-current">
            Participants
          </h3>
          <div className="flex flex-row flex-wrap gap-1 md:justify-normal">
            {activity.participants &&
              activity.participants.length > 0 &&
              activity.participants.map((participant) => (
                <div
                  key={participant.user_id}
                  className="bg-gray-100 p-4 rounded-lg tex-center"
                  style={{ width: '150px' }}
                >
                  <img
                    alt={`${participant.first_name} ${participant.last_name}`}
                    src={participant.profile_pic_url}
                    className="w-8 h-8 rounded-full mb-2 mx-auto object-cover"
                  />
                  <p className="text-sm font-semibold text-center">{`${participant.first_name} ${participant.last_name}`}</p>
                  {participant.is_owner && (
                    <p className="text-xs text-green-800 text-center mx-auto">
                      Host
                    </p>
                  )}
                </div>
              ))}
          </div>
          {activity.pay_and_join && !alreadyParticitpant && (
            <div className="mt-4 w-1/2 lg:w-1/6 text-center mx-auto">
              <PayNowButton label="Pay and join" onClick={handleJoinClick} />
            </div>
          )}

          {alreadyParticitpant && (
            <div className="mt-4 text-center mx-auto">
              <Button onClick={handleGroupChat} className=' bg-gradient-to-br from-gray-800 to-green-800 text-white'>
                <IoChatbubbleEllipsesOutline className="mr-2" size={20} />
                Chat with players
              </Button>
            </div>
          )}
        </div>
      </div>

      {currentUser.id === activity.user_id && (
        <div className="bg-white shadow-xl p-8 rounded-md mt-8  md:w-full">
          <h3 className="text-xl font-bold mb-4">Participant Requests</h3>
          <ActivityRequestTable
            activityRequests={activity.activity_requests}
            ownerId={activity.user_id}
          />
        </div>
      )}
    </section>
  )
}
