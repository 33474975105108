import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { getAccessToken, setAccessToken } from "../lib/utils";
const axiosClient: AxiosInstance = axios.create({
  baseURL: "https://api.playasport.co.uk/api/v1",
});

axiosClient.interceptors.request.use((config) => {
  const token = getAccessToken();
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

axiosClient.interceptors.response.use(
  (response: AxiosResponse) => {
    const token = response.data.token;
    if (token) {
      setAccessToken(token)
    }

    return response.data;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export default axiosClient;
