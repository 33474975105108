import { DocumentData, addDoc, collection, getDocs, orderBy, query } from 'firebase/firestore'
import { db } from './firebase'
import { IMessageChatRequest} from '../types'

export const fetchActivityChatCollection = async (activityId: number) => {
    const messagesQuery = query(
    collection(db, `activity_chats/${activityId}/messages`),
    orderBy('timestamp', 'asc') // 'asc' for ascending order, 'desc' for descending
  );

  const querySnapshot = await getDocs(messagesQuery);

    const messages: DocumentData[] = []

    querySnapshot.forEach((doc) => {
      messages.push(doc.data())
    });
  
    return messages;
};
  

export const addMessageToFirebase = async (data: IMessageChatRequest) => {
    const collectionRef = collection(
      db,
      `activity_chats/${data.activityId}/messages`,
    )
    const docRef = await addDoc(collectionRef, data)
    return { id: docRef.id, ...data }
}