// Import the functions you need from the SDKs you need
// firebase.js
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'


const firebaseConfig = {
  apiKey: 'AIzaSyBEhOpgbIRTjsoxZGn-y0Xcp-hr3czidHY',
  authDomain: 'playasport-c5f04.firebaseapp.com',
  projectId: 'playasport-c5f04',
  storageBucket: 'playasport-c5f04.firebasestorage.app',
  messagingSenderId: '1025189871258',
  appId: '1:1025189871258:web:3126851708b416b82da923',
  measurementId: 'G-7HSY094W4W'
};

const app = initializeApp(firebaseConfig)

export const db = getFirestore(app)
