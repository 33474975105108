import { CustomFlowbiteTheme } from 'flowbite-react'

export const customTheme: CustomFlowbiteTheme = {
  sidebar: {
    root: {
      inner: 'bg-white py-3 border-r-2 shadow-lg h-full rounded-none',
    },
    logo: {
      img: "w-full px-2"
    }
  },
  textInput: {
    field: {
      input: {
        base: 'block w-full overflow-hidden rounded-lg border disabled:cursor-not-allowed disabled:opacity-50 border-green-800 focus:ring-green-800 focus:border-green-800',
        colors: {
          gray: 'focus:ring-gray-200 border-gray-200',
          info: 'focus:ring-green-800 border-green-800',
        },
      },
    },
  },
  button: {
    base: 'font-bold',
    color: {
      info: 'bg-gradient-to-br from-gray-800 to-green-800 text-white focus:bg-green-700 focus:ring-green-700 focus:border-green-800',
    },
  },
  datepicker: {
    views: {
      days: {
        items: {
          item: {
            selected: 'bg-green-700 text-white hover:bg-gradient-to-br from-gray-800 to-green-800',
          },
        },
      },
    },
  },
  checkbox: {
    root: {
      color: {
        default: 'text-green-800'
      }
    }
  },
  table: {
    root: {
      wrapper: 'w-[75vw] md:w-full overflow-x-auto'
    },
    head: {
      base: "group/head text-xs uppercase text-green-800",
      cell: {
        base: "group-first/head:first:rounded-tl-lg group-first/head:last:rounded-tr-lg bg-gray-50 px-3 py-3"
      }
    },
    body: {
      base: "group/body",
      cell: {
        base: "group-first/body:group-first/row:first:rounded-tl-lg group-first/body:group-first/row:last:rounded-tr-lg group-last/body:group-last/row:first:rounded-bl-lg group-last/body:group-last/row:last:rounded-br-lg px-3 py-3"
      }
    }
  },
  tabs: {
    tablist: {
      tabitem: {
        base: "flex items-center justify-center p-2 md:p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 focus:outline-none",
        icon: "mr-2 h-7 w-7 text-green-800",
        styles: {
          underline: {
            active: {
              on: "text-green-800 rounded-t-lg border-b-2 border-green-800 active border focus:border focus:border-green-800 focus:border-6"
            }
          }
        }
      }
    }
  }
}
