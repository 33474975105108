import React from 'react';
import { GiShuttlecock, GiTennisCourt } from 'react-icons/gi'
import { FaBasketball } from 'react-icons/fa6'
import { MdPayment, MdSportsHandball, MdSportsRugby } from 'react-icons/md'
import { IoMdFootball, IoMdTime } from 'react-icons/io'
import { FcApproval } from 'react-icons/fc'
import { FaUserTimes } from 'react-icons/fa'

import { TbCricket } from 'react-icons/tb'
import { customStyles } from '../ui/styles'
import { Button } from 'flowbite-react'
import { BsFillSendPlusFill } from 'react-icons/bs'
import { IParticipant } from '../types'
import { googleLogout } from '@react-oauth/google'
import { MdPayments } from "react-icons/md";

export const setAccessToken = (token: string) => {
  localStorage.setItem("accessToken", token);
}

export const getAccessToken = (): string | null => {
  return localStorage.getItem("accessToken")
}

export const signOut = () => {
  localStorage.removeItem("accessToken");
  googleLogout();
  window.location.href = '/login'
}
export const getSportIcon = (sportType: string) => {
  switch (sportType) {
    case 'basketball':
      return <FaBasketball className="mr-2" style={customStyles} />
    case 'rugby':
      return <MdSportsRugby className="mr-2" style={customStyles} />
    case 'cricket':
      return <TbCricket className="mr-2" style={customStyles} />
    case 'tennis':
      return <GiTennisCourt className="mr-2" style={customStyles} />
    case 'badminton':
      return <GiShuttlecock className="mr-2" style={customStyles} />
    case 'football':
      return <IoMdFootball className="mr-2" style={customStyles} />
    default:
      return <MdSportsHandball className="mr-2" style={customStyles} />
  }
}

export const formatTime = (startTime: string, endTime: string): string => {
  const formattedStartTimeHour = new Date(startTime).getHours()
  const formattedStartTimeMin = new Date(startTime).getMinutes().toString().padStart(2, '0')

  const formattedEndTimeHour = new Date(endTime).getHours()
  const formattedEndTimeMin = new Date(endTime).getMinutes().toString().padStart(2, '0')

  return `${formattedStartTimeHour}:${formattedStartTimeMin} - ${formattedEndTimeHour}:${formattedEndTimeMin}`
}

export const formatDate = (activityDate: Date) => {
  return new Date(activityDate).toLocaleDateString('en-UK', {
    weekday: 'short', // Short weekday name (e.g., "Sat")
    day: 'numeric', // Numeric day of the month
    month: 'short', // Short month name (e.g., "Dec")
  })
}

export const renderStateIcon = (
  requestState: 'pending' | 'approved' | 'rejected' | 'payment_pending',
) => {
  switch (requestState) {
    case 'pending':
      return <IoMdTime className="mr-2 text-yellow-600" />
    case 'approved':
      return <FcApproval className="mr-2 text-green-800" />
    case 'rejected':
      return <FaUserTimes className="mr-2 text-red-600" />
    case 'payment_pending':
      return <MdPayment className="mr-1 text-yellow-600" />
    default:
      return null
  }
}

export const renderRequestState = (
  requestState: 'pending' | 'approved' | 'rejected' | 'payment_pending',
) => {
  switch (requestState) {
    case 'pending':
      return <span className="text-yellow-600">{requestState}</span>
    case 'approved':
      return <span className="text-green-800">{requestState}</span>
    case 'rejected':
      return <span className="text-red-600">{requestState}</span>
    case 'payment_pending':
      return <span className="text-yellow-600 text-xs">{'Payment Pending'}</span>
    default:
      return null
  }
}

export const renderActivityRequestState = (
  requestState: 'pending' | 'approved' | 'rejected',
) => {
  return (
    <div className="text-green-800 font-semibold flex items-center">
      <div>{renderStateIcon(requestState)}</div>
      <div className="capitalize">{renderRequestState(requestState)}</div>
    </div>
  )
}

export const renderJoinButton = (
  handleJoinClick: (e: React.MouseEvent<HTMLButtonElement>) => void,
  text: string,
  payAndJoin: boolean,
) => (
  <Button.Group>
    <Button
      color="green"
      className="border-green-800 rounded-md border-dotted"
      onClick={(e) => {
        e.stopPropagation()
        handleJoinClick(e)
      }}
    >
      <div className='flex flex-row items-center'>
        {payAndJoin ? (<MdPayments size={15} className="mr-2" style={customStyles} />) : (<BsFillSendPlusFill size={15} className="mr-2" style={customStyles} />)}
        {text}
      </div>
    </Button>
  </Button.Group>
)


export const hostProfilePicUrl = (participants: IParticipant[]): string => {
  const ownerParticipant = participants.find(participant => participant.is_owner);
  return ownerParticipant ? ownerParticipant.profile_pic_url : '';
}
