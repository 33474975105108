import { getSportIcon } from "../../lib/utils" 
import React from 'react'
export interface ICard {
  text: string
  onClick?: () => void
  active?: boolean
}
export const Chip: React.FC<ICard> = ({ text, active = false, onClick }) => {
  return (
    <div className="m-3 cursor-pointer" onClick={onClick}>
      <span id="badge-dismiss-green" className={`inline-flex items-center px-2 py-1 me-2 text-sm font-medium capitalize text-green-800 bg-green-100 rounded ${active ? 'border border-green-800 shadow-md' : ' '}`}>
        {getSportIcon(text)}
        {text}
      </span>
    </div>
  )
}
