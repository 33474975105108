import { Label } from 'flowbite-react'
import { default as RSSelect, SingleValue } from 'react-select'
import { ColourOption, ISelectProps } from '../../types'
import React from 'react'

export const Select: React.FC<ISelectProps> = ({
  name,
  title,
  options,
  onChange,
  error,
}) => {
  const handleChange = (option: SingleValue<ColourOption>) => {
    if (option != null) {
      onChange(option.value)
    }
  }
  return (
    <div>
      <div className="mb-2 block">
        <Label htmlFor={name} value={title} />
      </div>
      <RSSelect
        options={options}
        onChange={(option) => handleChange(option)}
        className="capitalize"
        styles={{
          input: (base) => ({
            ...base,
            'input:focus': {
              boxShadow: 'none',
            },
          }),
        }}
      />
      <p className="text-red-500 text-sm">{error}</p>
    </div>
  )
}
