import React from 'react'
import { Table } from 'flowbite-react'
import { useQuery } from 'react-query'
import { getPayments } from '../../services/ApiClient'
import SkeletonPaymentsTable from './SkeletonPaymentsTable'
import { PayNowButton } from '../../components/atoms/PayNowButton'
import { Payment } from '../../types'
import { RiCalendar2Fill } from 'react-icons/ri'
import { getSportIcon } from '../../lib/utils'

export const PaymentsTable: React.FC = () => {
  const { data, isLoading } = useQuery('getPayments', () => getPayments())

  if (isLoading) {
    return <SkeletonPaymentsTable />
  }

  const getStatusColor = (status: string) => {
    return status === 'unpaid'
      ? 'font-bold text-red-600'
      : 'font-bold text-green-800'
  }

  return (
    <section className="h-screen">
      <div className="flex w-5/6 bg-white-100 text-center flex-wrap mx-auto mt-5">
        <div className=" overflow-x-auto">
          <Table
            hoverable
            className={'border-green-800 border-1 rounded-lg shadow-2xl text-xs border-1 hover:cursor-pointer'}
          >
            <Table.Head style={{ backgroundColor: "white" }}>
              <Table.HeadCell style={{ width: '5%' }}>ID</Table.HeadCell>
              <Table.HeadCell style={{ width: '20%' }} >Host Name</Table.HeadCell>
              <Table.HeadCell style={{ width: '20%' }}>
                <span className="flex flex-row items-center">
                  <RiCalendar2Fill className="mr-2" size={10} />{' '}
                  <span>Game Date</span>
                </span>
              </Table.HeadCell>
              <Table.HeadCell style={{ width: '10%' }}>Type</Table.HeadCell>
              <Table.HeadCell style={{ width: '40%' }}>
                Game Unique Name
              </Table.HeadCell>
              <Table.HeadCell style={{ width: '5%' }}>Players</Table.HeadCell>
              <Table.HeadCell style={{ width: '20%' }}>Status</Table.HeadCell>
              <Table.HeadCell style={{ width: '10%' }}>Amount</Table.HeadCell>
              <Table.HeadCell style={{ width: '10%' }}>
                <span className="sr-only">Action</span>
              </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y font-xs capitalize">
              {data &&
                data.payments &&
                data.payments.map((payment: Payment) => {
                  return (
                    <Table.Row
                      key={payment.id}
                      className="bg-white text-xs"
                    >
                      <Table.Cell>{payment.activity_id}</Table.Cell>
                      <Table.Cell style={{ width: '15%' }}>
                        <span className="flex flex-row items-center">
                          <img
                            src={payment.activity_host_picture}
                            alt="Host"
                            style={{
                              width: '30px',
                              height: '30px',
                              borderRadius: '50%',
                              marginRight: '10px',
                            }}
                          />
                          <span>{payment.host_name}</span>
                        </span>
                      </Table.Cell>
                      <Table.Cell style={{ width: '15%' }} className="font-bold">
                        {payment.activity_date}
                      </Table.Cell>
                      <Table.Cell style={{ width: '10%' }}>
                        <span className='flex flex-row items-center capitalize'>
                          {getSportIcon(payment.activity_type)}{payment.activity_type}
                        </span>
                      </Table.Cell>
                      <Table.Cell style={{ width: '40%' }}>
                        {payment.activity_name}
                      </Table.Cell>
                      <Table.Cell style={{ width: '10%' }}>
                        {payment.participants_count}
                      </Table.Cell>
                      <Table.Cell
                        style={{ width: '20%' }}
                        className={getStatusColor(payment.status)}
                      >
                        {payment.status}
                      </Table.Cell>
                      <Table.Cell style={{ width: '10%' }} className="font-bold">
                        £{payment.amount}
                      </Table.Cell>
                      <Table.Cell style={{ width: '10%' }}>
                        {payment.status !== 'paid' && (<PayNowButton label="pay" size="xs" />)}
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
            </Table.Body>
          </Table>


        </div>
      </div>
    </section>
  )
}
