import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import ErrorBoundary from './lib/error-boundary'
import { Toaster } from 'react-hot-toast'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { Flowbite } from 'flowbite-react'
import store from './redux/store'
import { AppRoutes } from './routes'
import { customTheme } from './ui/theme'
import CookiePopup from './components/compounds/CookiePopup'

const queryClient = new QueryClient()
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "1025189871258-ophffffm1mfop553hs3dfur9nrqff01h.apps.googleusercontent.com"

const App = () => {

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div><Toaster position="top-center" /></div>
      <CookiePopup />
      <Provider store={store}>
        <Flowbite theme={{ theme: customTheme }}>
          <ErrorBoundary>
            <React.StrictMode>
              <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                  <AppRoutes />
                </BrowserRouter>
              </QueryClientProvider>
            </React.StrictMode>
          </ErrorBoundary>
        </Flowbite>
      </Provider>
    </GoogleOAuthProvider>
  )
}

export default App
