import { ILoginGlobalState } from '../../types'
import { SET_USER } from '../actions/LoginActions'

// loginReducer.js
const initialState: ILoginGlobalState = {
  user: {
    id: 1,
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '123123123',
  },
}

const loginReducer = (
  state = initialState,
  action: { type: unknown; payload: unknown },
) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload }
    default:
      return state
  }
}

export default loginReducer
