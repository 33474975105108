import { useFormik } from 'formik'
import { Button, Card, Spinner } from 'flowbite-react'
import { TextInput } from '../../components/atoms'
import { IoLogIn, IoPersonAdd } from 'react-icons/io5'
import { RegisterValidationSchema } from '../../formik/validations/RegisterValidationSchema'
import { Link, useNavigate } from 'react-router-dom'
import { InitialValues } from './InitialValues'
import { useMutation } from 'react-query'
import { registerUser } from '../../services/ApiClient'
import { IAxiosError, IRegisterInitialValues } from '../../types'
import { toast } from 'react-hot-toast'

export function RegisterForm() {
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: InitialValues,
    validationSchema: RegisterValidationSchema,
    onSubmit: (values) => {
      registerMutation.mutateAsync(values)
    },
  })

  const { values } = formik

  const registerMutation = useMutation(
    async (values: IRegisterInitialValues) => await registerUser(values),
    {
      onSuccess: () => {
        toast.success('Account created successfully 🎉')
        navigate('/otp-verification', { state: { phoneNumber: values.phone_number } })
      },
      onError: (error: IAxiosError) => {
        toast.error(error.response.data.message.toString() as string)
      },
    },
  )

  return (
    <div className="flex flex-col items-center justify-center">
      <Card className="w-full md:w-3/4 px-3 py-1 rounded-none border-none md:border md:rounded">
        <div className='text-xl text-center font-bold'>Create an account</div>
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col gap-3 w-full"
        >
          <div>
            <TextInput
              title="First Name"
              name="first_name"
              type="text"
              placeholder="John"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.first_name}
              error={formik.errors.first_name}
            />
          </div>
          <div>
            <TextInput
              title="Last Name"
              name="last_name"
              type="text"
              placeholder="Doe"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.last_name}
              error={formik.errors.last_name}
            />
          </div>
          <div>
            <TextInput
              title="Email"
              name="email"
              type="email"
              placeholder="user@gmail.com"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.errors.email}
            />
          </div>
          <div>
            <TextInput
              title="Password"
              name="password"
              type="password"
              placeholder="********************"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.errors.password}
            />
          </div>
          <div>
            <TextInput
              title="Confirm Password"
              name="confirm_password"
              type="password"
              placeholder="********************"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirm_password}
              error={formik.errors.confirm_password}
            />
          </div>
          <div>
            <TextInput
              title="Phone Number"
              name="phone_number"
              type="tel"
              placeholder="1234567890"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone_number}
              error={formik.errors.phone_number}
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              addon="+44"
            />
          </div>

          <Button
            type="submit"
            className="text-center mx-auto w-full px-2 py-1 border flex gap-2 hover:shadow transition duration-150 tex-white mt-5 bg-gradient-to-tl from-gray-800 to-green-800"
            disabled={registerMutation.isLoading}
          >
            {registerMutation.isLoading ? (
              <>
                <Spinner aria-label="Spinner button example" size="sm" />
                <span className="pl-3">Registering...</span>
              </>
            ) : (
              <>
                <IoPersonAdd className="mr-2" size={20} /> Register
              </>
            )}
          </Button>

          <Button
            className="px-1 border flex hover:shadow transition duration-150 text-white bg-gradient-to-br from-gray-800 to-green-800"
            gradientDuoTone={'pinkToOrange'}
            as={Link}
            to={'/login'}
          >
            <IoLogIn className="mr-2" size={30} />
            <span>Login</span>
          </Button>
        </form>
      </Card>
    </div>
  )
}
