import React from 'react'

import { Button, Spinner, Table } from 'flowbite-react'
import { IActivityRequestResponse } from '../../types'
import { renderActivityRequestState } from '../../lib/utils'
import { useSelector } from 'react-redux'
import { selectUser } from '../../redux/selectors'
import { HiCheck } from 'react-icons/hi'
import { ImCross } from 'react-icons/im'
import { useMutation, useQueryClient } from 'react-query'
import { updateActivityRequest } from '../../services/ApiClient'

const renderButton = (
  onClick: () => void,
  isLoading: boolean,
  icon: React.ReactNode,
  text: string,
) => (
  <Button
    color="gray"
    gradientDuoTone={text === 'Approve' ? 'greenToBlue' : 'pinkToOrange'}
    onClick={onClick}
    disabled={isLoading}
  >
    {isLoading ? (
      <>
        <Spinner aria-label="Spinner button example" size="sm" />
        <span className="pl-3">Loading...</span>
      </>
    ) : (
      <>
        {icon}
        {text}
      </>
    )}
  </Button>
)

export const ActivityRequestTable: React.FC<{
  activityRequests: IActivityRequestResponse[]
  ownerId: number
}> = ({ activityRequests, ownerId }) => {
  const currentUser = useSelector(selectUser)

  const queryClient = useQueryClient()

  const approveMutation = useMutation(
    (id: number) => updateActivityRequest({ id, state: 'approved' }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getActivity')
      },
    },
  )

  const rejectMutation = useMutation(
    (id: number) => updateActivityRequest({ id, state: 'rejected' }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getActivity')
      },
    },
  )

  return (
    <Table striped hoverable className="table-auto">
      <Table.Head>
        <Table.HeadCell>Requestor Name</Table.HeadCell>
        <Table.HeadCell>Status</Table.HeadCell>
        {ownerId === currentUser.id && (
          <Table.HeadCell>
            <span className="sr-only">Accept / Reject</span>
          </Table.HeadCell>
        )}
      </Table.Head>
      <Table.Body className="divide-y">
        {activityRequests &&
          activityRequests.length > 0 &&
          activityRequests.map((activityRequest) => {
            return (
              <Table.Row className="bg-white">
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900">
                  <div className="flex flex-row items-center">
                    <img
                      alt="Bonnie"
                      src={activityRequest.requestor_pic_url}
                      className="rounded-full shadow-md mr-2"
                      width={30}
                      height={30}
                    />
                    <div>{activityRequest.requestor_name}</div>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  {renderActivityRequestState(activityRequest.state)}
                </Table.Cell>
                {ownerId === currentUser.id &&
                  activityRequest.state === 'pending' && (
                    <Table.Cell>
                      <div className="flex flex-row justify-between w-3/4 mx-auto">
                        {renderButton(
                          () => approveMutation.mutate(activityRequest.id),
                          approveMutation.isLoading,
                          <HiCheck className="mr-3 h-4 w-4" />,
                          'Approve',
                        )}
                        {renderButton(
                          () => rejectMutation.mutate(activityRequest.id),
                          rejectMutation.isLoading,
                          <ImCross className="mr-3 h-4 w-4" />,
                          'Reject',
                        )}
                      </div>
                    </Table.Cell>
                  )}
              </Table.Row>
            )
          })}
      </Table.Body>
    </Table>
  )
}
