import { IconType } from 'react-icons'
import { BiSolidTennisBall } from 'react-icons/bi'
import { FaWhatsapp } from 'react-icons/fa6'
import {
  HiOutlineFire,
  HiOutlineUserCircle,
} from 'react-icons/hi'
import { MdSportsCricket } from 'react-icons/md';

// import { FaPoundSign } from "react-icons/fa";

import { Link } from 'react-router-dom'
interface ISideBarItemProps {
  icon: IconType
  text: string
  to?: string
  className?: string
  // TODO: Type this to tailwindcss type
}
import React from 'react'

const SideBarItem: React.FC<ISideBarItemProps> = ({
  icon,
  text,
  to = '#',
}: ISideBarItemProps) => {
  return (
    <Link to={to} className="cursor-pointer hover:bg-green-700 hover:border hover:p-1 hover:cursor-pointer hover:border-white hover:rounded-md flex flex-row items-center">
      <div>
        {icon({
          size: '25',
          style: {
            color: 'white',
            padding: '',
          },
          title: text,
        })}
      </div>
    </Link>
  )
}

export const Mobile = () => {
  return (
    <div className="flex flex-row gap-10 flex-1 w-full justify-evenly bg-gradient-to-br from-gray-800 to-green-800 px-4 h-16 bottom-0 sticky items-center">
      <SideBarItem icon={BiSolidTennisBall} text="Let's play" to="/" />
      <SideBarItem icon={FaWhatsapp} text="Messages" to="/messages" />
      <SideBarItem icon={HiOutlineFire} text="My Activities" to="/activities" />
      <SideBarItem
        icon={MdSportsCricket}
        text="Host Activity"
        to="/activity/create"
      />
      {/* <SideBarItem
        icon={FaPoundSign}
        text="Host Activity"
        to="/payments"
      /> */}
      <SideBarItem
        icon={HiOutlineUserCircle}
        text="My Profile"
        to="/my-profile"
      />
    </div>
  )
}
