import * as Yup from 'yup';

export const RegisterValidationSchema = Yup.object({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
    .required('Confirm Password is required'),
  phone_number: Yup.string().matches(/^\d{10}$/, 'Invalid phone number. It should be exactly 10 digits').required('Phone number is required'),
});
