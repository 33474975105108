// rootReducer.js
import { combineReducers } from 'redux'
import loginReducer from './LoginReducer'
// Import other reducers as needed

const rootReducer = combineReducers({
  login: loginReducer,
  // Add other reducers here
})

export default rootReducer
