import React from 'react'
import { getUserActivities } from '../../services/ApiClient'
import SkeletonActivityCard from '../activity/SkeletonActivityCard'
import { IActivityResponse } from '../../types'
import { ActivityCard } from '../../components/compounds/ActivityCard'
import { useQuery } from 'react-query'
import { Tabs } from 'flowbite-react'
import { HiUserCircle } from 'react-icons/hi'
import { MdDashboard } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { selectUser } from '../../redux/selectors'

export const MyActivites = () => {
  const currentUser = useSelector(selectUser)

  const { data, isLoading } = useQuery('fetchActivities', () =>
    getUserActivities(),
  )

  if (isLoading) {
    return (
      <div className="flex flex-row flex-wrap w-full">
        {[...Array(10)].map((_, index) => (
          <SkeletonActivityCard key={index} />
        ))}
      </div>
    )
  }

  // Separate activities into two arrays based on whether the user is the owner
  const hostedActivities = data?.activities.filter((activity) =>
    (activity.participants ?? []).some(
      (participant) =>
        participant.user_id === currentUser?.id && participant.is_owner,
    ),
  );
  const participatingActivities = data?.activities.filter((activity) =>
    (activity.participants ?? []).some(
      (participant) =>
        participant.user_id === currentUser?.id && !participant.is_owner,
    ),
  );

  const active = () => {
    if (participatingActivities && participatingActivities?.length > 0) {
      return "participating"
    } else {
      return "hosted"
    }
  }

  return (
    <Tabs aria-label="Full width tabs" style="underline">
      <Tabs.Item active={active() === 'participating'} title="Participating Activities" icon={MdDashboard}>
        <div className="flex flex-row flex-wrap mt-5 mx-auto gap-5 justify-center md:justify-normal">
          {participatingActivities &&
            participatingActivities.length > 0 &&
            (participatingActivities as IActivityResponse[]).map((activity: IActivityResponse) => {
              return (
                <ActivityCard
                  venueName={activity.venue_name}
                  imageUrl={activity.image_url}
                  sportType={activity.sport_type}
                  startTime={activity.start_time}
                  endTime={activity.end_time}
                  activityDate={activity.activity_date}
                  createdName={activity.user_first_name}
                  userId={activity.user_id}
                  id={activity.id}
                  activityRequests={activity.activity_requests}
                  participants={activity.participants}
                  payAndJoin={activity.pay_and_join}
                  googleMapUrl={activity.google_map_url}
                />
              )
            })}
        </div>
      </Tabs.Item>
      <Tabs.Item active={active() === 'hosted'} title="Hosted activities" icon={HiUserCircle}>
        <div className="flex flex-row flex-wrap mt-5 mx-auto gap-5 justify-center md:justify-normal">
          {hostedActivities &&
            hostedActivities.length > 0 &&
            (hostedActivities as IActivityResponse[]).map((activity: IActivityResponse) => {
              return (
                <ActivityCard
                  venueName={activity.venue_name}
                  imageUrl={activity.image_url}
                  sportType={activity.sport_type}
                  startTime={activity.start_time}
                  endTime={activity.end_time}
                  activityDate={activity.activity_date}
                  createdName={activity.user_first_name}
                  userId={activity.user_id}
                  id={activity.id}
                  activityRequests={activity.activity_requests}
                  participants={activity.participants}
                  payAndJoin={activity.pay_and_join}
                  googleMapUrl={activity.google_map_url}
                />
              )
            })}
        </div>
      </Tabs.Item>
    </Tabs>
  )
}
