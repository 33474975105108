// Home.tsx

import React, { useEffect, useState } from 'react'
import { ActivityCard } from '../../components/compounds/ActivityCard'
import SearchBar from '../../components/molecules/SearchBar'
import { getSearchActivities } from '../../services/ApiClient'
import { useQuery } from 'react-query'
import { IActivityResponse } from '../../types'
import SkeletonActivityCard from '../../features/activity/SkeletonActivityCard'
import { Chip } from '../../components/atoms/Chip'

const Home: React.FC = () => {
  const [query, setQuery] = useState<string>('')
  const [selectedSportType, setSelectedSportType] = useState<string>('')
  const { data, isLoading, refetch } = useQuery('searchActivity', () =>
    getSearchActivities({ search: query, sportType: selectedSportType }),
  )

  useEffect(() => {
    refetch()
  }, [query, refetch, selectedSportType])

  if (isLoading) {
    return (
      <>
        <div className="flex flex-row flex-wrap">
          <SearchBar
            onChange={(value) => {
              setQuery(value)
            }}
          />
        </div>
        <div className="flex flex-row flex-wrap w-full">
          {[...Array(10)].map((_, index) => (
            <SkeletonActivityCard key={index} />
          ))}
        </div>
      </>
    )
  }

  const sports = [
    'All Sports',
    'basketball',
    'rugby',
    'cricket',
    'tennis',
    'badminton',
    'football',
  ]

  return (
    <>
      <div className="flex flex-row flex-wrap m-3">
        <SearchBar
          onChange={(value) => {
            setQuery(value)
          }}
        />
      </div>
      {/* <div className="flex flex-row flex-wrap">
        <Chip text="Today" />
        <Chip text="Tomorrow" />
        <Chip text="This week" />
        <Chip text="This month" />
      </div> */}

      <div className="flex flex-row flex-wrap justify-normal">
        {sports.map((sport, index) => (
          <Chip
            key={index}
            text={sport}
            onClick={() => {
              setSelectedSportType(sport)
            }}
            active={selectedSportType === sport}
          />
        ))}
      </div>

      <div className="flex flex-row flex-wrap w-full justify-normal gap-0">
        {data &&
          data.activities &&
          data.activities.length > 0 &&
          data.activities.map((activity: IActivityResponse) => {
            return (
              <ActivityCard
                venueName={activity.venue_name}
                imageUrl={activity.image_url}
                sportType={activity.sport_type}
                startTime={activity.start_time}
                endTime={activity.end_time}
                activityDate={activity.activity_date}
                createdName={activity.user_first_name}
                userId={activity.user_id}
                id={activity.id}
                activityRequests={activity.activity_requests}
                pendingReqeuests={activity.pending_requests}
                participants={activity.participants}
                payAndJoin={activity.pay_and_join}
                googleMapUrl={activity.google_map_url}
              />
            )
          })}
      </div>
    </>
  )
}

export default Home
