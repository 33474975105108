import { IProfileFormInitialValue } from '../../types'

export const InitialValues: IProfileFormInitialValue = {
  first_name: '',
  last_name: "",
  email: '',
  phone_number: '',
  id: 0,
  profile_picture: ""
}
