import { Link, useLocation, useNavigate } from "react-router-dom"
import { OtpInput } from "../../components/atoms"
import { InitialValues } from "../otp/InitialValues"
import { useFormik } from "formik";
import { OtpValidationSchema } from '../../formik/validations/OtpValidationSchema'
import { IAxiosError, IOtpInitialValue } from "../../types";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import { sendOtp, verifyOtp } from "../../services/ApiClient";
import { Button, Card, Spinner } from "flowbite-react";
import { IoLogIn } from "react-icons/io5";
import { BsPhoneVibrate } from "react-icons/bs";
import { useEffect } from "react";


export const OtpVerification = () => {

  const navigate = useNavigate()
  const location = useLocation();
  const phoneNumber = location?.state?.phoneNumber;

  if (!phoneNumber) {
    navigate('/register')
  }

  const sendOtpMutation = useMutation(
    async () => {
      const phoneNumber = location.state.phoneNumber;
      if (!phoneNumber) {
        throw new Error("Phone number is not provided");
      }
      await sendOtp({ phone_number: phoneNumber });
    },
    {
      onSuccess: () => {
        toast.success('OTP sent successfully 🎉')
      },
      onError: (error: Error) => {
        toast.error(error.message)
      },
    },
  )

  const otpMutation = useMutation(
    async (values: IOtpInitialValue) => await verifyOtp(values, location.state.phoneNumber),
    {
      onSuccess: () => {
        toast.success('OTP verified successfully 🎉. Please log-in')
        navigate('/login')
      },
      onError: (error: IAxiosError) => {
        toast.error(error.response.data.message.toString() as string)
      },
    },
  )

  const formik = useFormik({
    initialValues: InitialValues,
    validationSchema: OtpValidationSchema,
    onSubmit: (values) => {
      otpMutation.mutateAsync(values)
    },
  })

  useEffect(() => {
    sendOtpMutation.mutate();
  }, []);


  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <Card className="w-full md:w-3/4 px-3 py-1 rounded-none border-none md:border md:rounded">
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col gap-2 w-full"
        >
          <div>
            <OtpInput
              name='otp'
              title="Please enter the OTP"
              className='OTP'
              value={formik.values.otp}
              onChange={(otp) => formik.setFieldValue('otp', otp)}
              onBlur={() => formik.setFieldTouched('otp', true)}
              error={formik.errors.otp}
            />
          </div>

          <Button
            type="submit"
            className="text-center mx-auto w-full px-2 py-1 border flex gap-2 hover:shadow transition duration-150 tex-white mt-5  bg-gradient-to-tl from-gray-800 to-green-800"
            disabled={otpMutation.isLoading}
          >
            {otpMutation.isLoading ? (
              <>
                <Spinner aria-label="Spinner button example" size="sm" />
                <span className="pl-3">Verifying</span>
              </>
            ) : (
              <>
                <BsPhoneVibrate className="mr-2" size={20} /> Verify OTP
              </>
            )}
          </Button>

          <Button
            className="px-1 border flex hover:shadow transition duration-150 text-white  bg-gradient-to-br from-gray-800 to-green-800"
            gradientDuoTone={'greenToBlue'}
            as={Link}
            to={'/register'}
          >
            <IoLogIn className="mr-2" size={30} />
            <span>Back to Create Account</span>
          </Button>
        </form>
      </Card>
    </div>)
}