import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { IoChatbubbleEllipsesOutline } from 'react-icons/io5';
import { Button } from 'flowbite-react'; // Replace with your actual Button component

const SkeletonGroupChatCard = () => {
  return (
    <div className="bg-white rounded-lg border border-gray-300 px-4 py-2 shadow-lg flex flex-row justify-between items-center text-green-800">
      <div>
        <Skeleton circle height={30} width={30} />
      </div>
      <div className=''>
        <Skeleton width={120} />
      </div>
      <div className='flex flex-row items-center capitalize'>
        <Skeleton width={50} />
      </div>
      <div>
        <Skeleton width={120} />
      </div>
      <div>
        <Button disabled className='bg-gradient-to-br from-gray-800 to-green-800 text-white'>
          <IoChatbubbleEllipsesOutline className="mr-2" size={20} />
          Chat with players
        </Button>
      </div>
    </div>
  );
};

export default SkeletonGroupChatCard;
