import { Button } from "flowbite-react"
import { IoChatbubbleEllipsesOutline } from "react-icons/io5"
import { getSportIcon, formatDate, formatTime, hostProfilePicUrl } from "../../lib/utils"
import { IActivityCard } from "../../types"
import { useNavigate } from "react-router-dom";
import React from 'react'

export const GroupChatCard: React.FC<IActivityCard> = ({
  venueName,
  startTime,
  endTime,
  activityDate,
  createdName,
  id,
  participants,
  googleMapUrl
}) => {
  const navigate = useNavigate()
  const handleAcivityView = () => {
    navigate(`/activities/${id}`)
  }

  const handleGroupChat = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`/messages/activity_chat/${id}`)
  }

  return (
    <div onClick={handleAcivityView} className="bg-white rounded-lg border border-gray-300 px-4 py-3 shadow-lg flex flex-col md:flex-row gap-2 md:gap-0 justify-between items-center text-green-800 md:w-full transform transition-transform hover:scale-105">
      <div>
        <img
          alt="Bonnie"
          src={hostProfilePicUrl(participants)}
          className="w-12 h-12 rounded-full mr-1 mx-auto object-cover"
        />
      </div>
      <div className=''>
        {createdName}' Activity
      </div>
      <a href={googleMapUrl} target="blank" className='underline  text-blue-600 md:text-center'>
        <div className=''>
          {venueName?.split(",")[0]}
        </div>
      </a>
      <div className='flex flex-row items-center capitalize'>
        {getSportIcon("badminton")} {"badminton"}
      </div>
      <div>
        {formatDate(activityDate)} &#x2022; {formatTime(startTime, endTime)}
      </div>
      <div>
        <Button onClick={handleGroupChat} className=" bg-gradient-to-br from-gray-800 to-green-800 text-white">
          <IoChatbubbleEllipsesOutline className="mr-2" size={20} />
          Chat with players
        </Button>
      </div>
    </div>
  )
}