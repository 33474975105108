import React from 'react'
import { BsPersonCheck } from 'react-icons/bs'
import { IActivityCard, IAxiosError } from '../../types'
import { customStyles } from '../../ui/styles'
import {
  formatDate,
  formatTime,
  getSportIcon,
  hostProfilePicUrl,
  renderActivityRequestState,
  renderJoinButton,
} from '../../lib/utils'
import { Button, Spinner } from 'flowbite-react'
import { selectUser } from '../../redux/selectors'
import { useSelector } from 'react-redux'
import { useMutation, useQueryClient } from 'react-query'
import { joinActivityRequest } from '../../services/ApiClient'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { IoMdTime } from 'react-icons/io'

export const ActivityCard: React.FC<IActivityCard> = ({
  venueName,
  imageUrl,
  sportType,
  startTime,
  endTime,
  activityDate,
  createdName,
  userId,
  id,
  activityRequests,
  pendingReqeuests,
  participants,
  payAndJoin,
}) => {
  const navigate = useNavigate()
  const currentUser = useSelector(selectUser)
  const queryClient = useQueryClient()

  const joinActivityMutation = useMutation(() => joinActivityRequest({ id }), {
    onSuccess: (data) => {
      const message = payAndJoin
        ? 'Redirecting to payment page'
        : 'Request Sent to the host successfully'
      toast.success(message)
      if (payAndJoin) {
        window.location.href = data.payment_link
      }

      queryClient.invalidateQueries('searchActivity')
    },
    onError: (error: IAxiosError) => {
      toast.error(error.response.data.message)
      queryClient.invalidateQueries('searchActivity')
    },
  })

  const handleJoinClick = () => {
    joinActivityMutation.mutate()
  }

  const getCurrentUserActivityRequestState = () => {
    const currentUserRequest = activityRequests.find(
      (request) => request.user_id === currentUser.id,
    )
    return currentUserRequest
      ? renderActivityRequestState(currentUserRequest.state)
      : null
  }

  const renderContent = () => {
    if (currentUser.id === userId) {
      return null // Render nothing if the current user is the owner
    }

    return (
      <div className="flex flex-row items-center">
        {joinActivityMutation.isLoading ? (
          <Spinner aria-label="Spinner button example" size="sm" />
        ) : (
          getCurrentUserActivityRequestState() ||
          renderJoinButton(
            handleJoinClick,
            payAndJoin ? 'Pay & Join' : 'Join',
            payAndJoin,
          )
        )}
      </div>
    )
  }

  const renderPendingRequests = () => {
    // user is the owner of the activity
    if (
      currentUser.id === userId &&
      pendingReqeuests &&
      pendingReqeuests.length > 0
    ) {
      return (
        <div className="flex flex-row items-center">
          <Button size={'xs'} gradientDuoTone="greenToBlue">
            <IoMdTime className="mr-2 text-white" />
            {pendingReqeuests.length}&nbsp; Requests
          </Button>
        </div>
      )
    }
  }

  const handleCardClick = () => {
    navigate(`/activities/${id}`)
  }

  return (
    <div
      className="rounded-md bg-white shadow-md hover:shadow-xl mx-3 my-5 cursor-pointer"
      style={{ width: '255px' }}
      onClick={handleCardClick}
    >
      {payAndJoin && (
        <div className="absolute bg-gradient-to-br from-gray-800 to-green-800 text-white px-3 rounded-br-lg shadow-2xl font-semibold text-xs py-2 ">
          Pay and join
        </div>
      )}


      <img
        src={imageUrl}
        alt="venue"
        className="rounded-t-md"
        width={'100%'}
        style={{ height: '200px' }}
      />
      <div
        className="flex flex-col justify-between"
        style={{ height: '150px' }}
      >
        <div className="px-3 py-3">
          <div className="text-green-800 font-semibold text-sm">
            {formatDate(activityDate)} &#x2022; {formatTime(startTime, endTime)}
          </div>
          <div className="font-semibold text-md text-gray-700 truncate">
            {venueName}
          </div>
          <div className="text-slate-700 flex flex-row justify-between items-center w-full text-md mt-3">
            <div className="flex items-center flex-1 font-normal text-sm capitalize">
              <>
                {getSportIcon(sportType)} {sportType}
              </>
            </div>
            {participants && participants.length > 0 && (
              <div className="flex items-center flex-1 font-normal text-sm justify-end">
                <BsPersonCheck className="mr-2" style={customStyles} />
                {participants.length + ' Going'}
              </div>
            )}
          </div>
        </div>
        {participants && participants.length > 0 && (
          <div className="bg-gray-200 px-3 py-2 rounded-b-md">
            <div className="flex items-center justify-between">
              <div className="flex flex-row items-center">
                <div className='align-center'>
                  <img
                    alt="Bonnie"
                    src={hostProfilePicUrl(participants)}
                    className="w-8 h-8 rounded-full mr-1 mx-auto object-cover"
                  />
                </div>

                <div className="flex flex-col">
                  <p className="font-normal text-xs text-green-800">
                    Hosted by
                  </p>
                  <p className="font-semibold text-sm">{createdName}</p>
                </div>
              </div>
              {renderContent()}
              {renderPendingRequests()}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
