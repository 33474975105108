import React from 'react'

import { Tooltip } from 'flowbite-react';
import { ITooltipProps } from '../../types';

export const ToolTip: React.FC<ITooltipProps> = ({
  children,
  text,
}) => {
  return (
    <Tooltip content={text} className='whitespace-pre-wrap max-w-[200px] bg-gradient-to-br from-gray-800 to-green-800'>
      {children}
    </Tooltip>
  )
}
