import React from 'react'

import { Label, TextInput as FBTextInput } from 'flowbite-react'
import { ITextInputProps } from '../../types'

export const TextInput: React.FC<ITextInputProps> = ({
  title,
  name,
  type,
  placeholder,
  error,
  className,
  ...props
}) => {
  return (
    <div className={className}>
      <div className="mb-2 block">
        <Label htmlFor={name} value={title} className={className} />
      </div>
      <FBTextInput id={name} type={type} placeholder={placeholder} className={className} {...props} />
      {error !== 'undefined' && (<p className='text-red-500 text-sm'>{error}</p>)}
    </div>
  )
}
