import { Label, TextInput as FBTextInput } from 'flowbite-react'
import { ICheckboxProps } from '../../types'
import React from 'react';

export const CheckBox: React.FC<ICheckboxProps> = ({
  title,
  name,
  error,
  onChange,
  checked,
}) => {
  return (
    <div className="flex items-center gap-2">
      <Label htmlFor={name} className="flex items-center">
        {title}
        <span className="relative ml-2">
          <FBTextInput id={name} type="checkbox" className="sr-only" checked={checked} onChange={onChange} />
          <span className={`block w-10 h-6 rounded-full transition-colors duration-200 ease-in-out ${checked ? 'bg-gradient-to-br from-gray-800 to-green-800' : 'bg-gray-500'}`}></span>
          <span className={`absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform duration-200 ease-in-out transform ${checked ? 'translate-x-full' : 'translate-x-0'}`}></span>
        </span>
      </Label>
      <p className='text-red-500 text-sm'>{error}</p>
    </div>
  )
}