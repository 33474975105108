// PaymentStatus.tsx

import { Spinner } from 'flowbite-react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getPaymentStatus } from '../../services/ApiClient'
import { useEffect, useState } from 'react'
import { FcPaid } from 'react-icons/fc'
import { customStyles } from '../../ui/styles'

export const PaymentStatus: React.FC = () => {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const paymentId = queryParams.get('payment_id')
  const [paymentStatus, setPaymentStatus] = useState<string>('pending')
  const [activityId, setActivityId] = useState<number>();
  const navigate = useNavigate()

  const fetchPaymentStatus = async (paymentId: string) => {
    try {
      const result = await getPaymentStatus(paymentId)
      setPaymentStatus(result.payment_status)
      setActivityId(result.activity_id)
    } catch (err) {
      console.log('🚀 ~ fetchPaymentStatus ~ err:', err)
    }
  }

  useEffect(() => {
    if (paymentId) {
      setInterval(() => {
        fetchPaymentStatus(paymentId)
      }, 1000)
    }
  }, [])

  if (paymentStatus === 'paid') {

    setTimeout(() => {
      navigate(`/activities/${activityId}`);
    }, 1000);


    return (
      <div className="flex flex-col justify-center items-center h-screen leading-5">
        <div>
          <FcPaid style={customStyles} size={100} className="mr-3" />
        </div>
        <div className="text-xl font-bold text-green-800 mb-4">Payment Successful!</div>
        <div className="text-lg text-center">
          Thank you for your payment. Your transaction was successful.
        </div>
        <div className="text-md text-center">
          Redirecting to activity......
        </div>
        <div className='text-center'>
          <Spinner size={'xl'} color={'success'} style={{ width: '10rem' }} />
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <div>
        <Spinner size={'xl'} color={'success'} style={{ width: '10rem' }} />
      </div>
      <div className="w-1/2 mt-10 text-center leading-10">
        Please wait a moment while we process your payment. Your transaction is
        being securely processed, and we appreciate your patience. <br /> For a
        smooth payment experience,{' '}
        <b className="uppercase">please do not go back or refresh this page.</b>
      </div>
    </div>
  )
}
