import React from 'react'

import { FileInput as FBFileInput, Label, FileInputProps } from 'flowbite-react';
export const FileInput: React.FC<FileInputProps> = ({
  title,
  name,
  placeholder,
  className,
  ...props
}) => {
  return (
    <div className={className}>
      <div className="mb-2 block">
        <Label htmlFor={name} value={title} className={className} />
      </div>
      <FBFileInput id={name} placeholder={placeholder} className={className} {...props} />
      {/* {error !== 'undefined' && (<p className='text-red-500 text-sm'>{error}</p>)} */}
    </div>
  )
}