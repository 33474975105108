import {
  IActivitySearchParams,
  IInitialValues,
  ISearchActivityResponse,
  ILoginInitialValues,
  IRegisterInitialValues,
  IActivityRequestResponse,
  IActivityResponse,
  IUserResponse,
  IProfileFormInitialValue,
  IPaymentsResponse,
  IOtpInitialValue,
} from '../types'

import axiosClient from './api'

const fetchActivities = async (): Promise<IInitialValues[]> => {
  try {
    const response = await axiosClient.get('/activities')
    return response as unknown as IInitialValues[]
  } catch (error: unknown) {
    if (typeof error === 'object' && error !== null && 'message' in error) {
      throw new Error(`Failed to fetch activities: ${(error as Error).message}`)
    } else {
      throw new Error('Failed to fetch activities: Unknown error')
    }
  }
}

const getSearchActivities = async (params: IActivitySearchParams) => {
  try {
    const url = '/search_activities'

    // Build the query parameters
    const queryParams = new URLSearchParams()

    if (params.search) queryParams.append('search', params.search)
    if (params.page) queryParams.append('page', params.page.toString())
    if (params.direction) queryParams.append('direction', params.direction)
    if (
      params.sportType !== undefined &&
      params.sportType !== '' &&
      params.sportType !== 'All Sports'
    ) {
      queryParams.append('sport_type', params.sportType)
    }

    const fullUrl = queryParams.toString()
      ? `${url}?${queryParams.toString()}`
      : url

    const response = await axiosClient.get(fullUrl)

    return response as unknown as ISearchActivityResponse
  } catch (error) {
    throw new Error(`Failed to fetch activities ${error}`)
  }
}

const createActivity = async (payload: IInitialValues) => {
  try {
    // Extract start_time and end_time from payload
    const { start_time, end_time, activity_date, ...restPayload } = payload
    // Format start_time and end_time by combining activity_date and the selected time
    const startTimeHours = Number(start_time.split(':')[0])
    const startTimeMins = Number(start_time.split(':')[1])

    const endTimeHours = Number(end_time.split(':')[0])
    const endTimeMins = Number(end_time.split(':')[1])

    const formattedStartTime = new Date(
      new Date(activity_date).setHours(startTimeHours, startTimeMins),
    )

    const formattedEndTime = new Date(
      new Date(activity_date).setHours(endTimeHours, endTimeMins),
    )
    // Create the updated payload with the formatted start_time and end_time
    const updatedPayload = {
      ...restPayload,
      start_time: formattedStartTime,
      end_time: formattedEndTime,
      activity_date,
    }

    const response = await axiosClient.post('/activities', updatedPayload)
    return response
  } catch (error) {
    throw new Error('Error creating activity:', error)
  }
}

const handleLogin = async (payload: ILoginInitialValues) => {
  const response = await axiosClient.post('/login', payload)
  return response as unknown as { token: string; user: IUserResponse }
}

const registerUser = async (payload: IRegisterInitialValues) => {
  const response = await axiosClient.post('/register', payload)
  return response
}

const joinActivityRequest = async (payload: { id: number }) => {
  const response = await axiosClient.post(
    `/activities/${payload.id}/activity_request`,
    payload,
  )
  return response as unknown as { payment_link: string }
}

const getUserActivities = async () => {
  try {
    const response = await axiosClient.get(`/activities`)
    return response as unknown as ISearchActivityResponse
  } catch (err) {
    throw new Error('Get activities failed', err)
  }
}

const getActivity = async (id: number) => {
  try {
    const response = await axiosClient.get(`/activities/${id}`)
    return response as unknown as IActivityResponse
  } catch (err) {
    throw new Error('Get activities failed', err)
  }
}

const updateActivityRequest = async (payload: {
  id: number
  state: 'approved' | 'rejected'
}) => {
  try {
    const response = await axiosClient.put(
      `/activity_request/${payload.id}`,
      payload,
    )
    return response as unknown as IActivityRequestResponse
  } catch (err) {
    throw new Error('Get activities failed', err)
  }
}

const updateUser = async (user: IProfileFormInitialValue) => {
  try {
    const formData = new FormData()
    formData.append('first_name', user.first_name)
    formData.append('last_name', user.last_name)
    formData.append('email', user.email)
    formData.append('phone_number', user.phone_number)
    formData.append('id', user.id.toString())

    if (user.profile_picture) {
      formData.append('profile_picture', user.profile_picture)
    }
    const response = await axiosClient.put(`/users/${user.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response as unknown as IUserResponse
  } catch (err) {
    throw new Error('Update User failed', err)
  }
}

const getUser = async () => {
  try {
    const response = await axiosClient.get(`/users/me`)
    return response as unknown as IUserResponse
  } catch (err) {
    throw new Error('Get User failed', err)
  }
}

const signInWithGoogle = async (accessToken: string) => {
  try {
    const response = await axiosClient.post('/auth/google_callback', {
      access_token: accessToken,
    })
    return response as unknown as { token: string; user: IUserResponse }
  } catch (err) {
    throw new Error('Get User failed', err)
  }
}

const getPayments = async () => {
  try {
    const response = await axiosClient.get('/payments')
    return response as unknown as IPaymentsResponse
  } catch (err) {
    throw new Error('Get User failed', err)
  }
}

const getPaymentStatus = async (paymentId: string) => {
  try {
    const response = await axiosClient.get(
      `/payments/${paymentId}/payment_status`,
    )
    console.log('🚀 ~ getPaymentStatus ~ response:', response)
    return response as unknown as {
      payment_status: string
      activity_id: number
    }
  } catch (err) {
    throw new Error('Get Payment Status failed', err)
  }
}

const verifyOtp = async (payload: IOtpInitialValue, phoneNumber: string) => {
  const response = await axiosClient.post('/otp/verify', {
    otp: payload.otp,
    phone_number: phoneNumber,
  })
  return response
}

interface ISendOtpPayload {
  phone_number: string
}

const sendOtp = async (payload: ISendOtpPayload) => {
  const response = await axiosClient.post('/otp/send', payload)
  return response
}

export {
  fetchActivities,
  createActivity,
  getSearchActivities,
  handleLogin,
  registerUser,
  joinActivityRequest,
  getUserActivities,
  getActivity,
  updateActivityRequest,
  updateUser,
  getUser,
  signInWithGoogle,
  getPayments,
  getPaymentStatus,
  verifyOtp,
  sendOtp,
}
