import React from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../redux/selectors'
import { Spinner } from 'flowbite-react'
import { Link } from 'react-router-dom'

export const ProfileCard: React.FC = () => {
  const user = useSelector(selectUser)
  if (!user) {
    return <Spinner aria-label="Spinner button example" size="xl" />
  }

  return (
    <Link to={`/my-profile`} className='border bg-red-600'>
      <div className="flex flex-col items-center pb-3 gap-1">
        <img
          className="w-14 h-14 rounded-full"
          src={user?.profile_picture?.url}
          alt="Profile"
        />

        <h5 className="mb-1 text-lg font-medium text-gray-900 capitalize hover:underline">
          {user.first_name}&nbsp;{user.last_name}
        </h5>
        <span className="text-sm text-gray-500 hover:underline">
          {user.email}
        </span>
        <span className="text-sm text-gray-500 hover:underline">
          {user.phone_number}
        </span>
      </div>
    </Link>
  )
}
