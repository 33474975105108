import { Button, Spinner } from 'flowbite-react'
import { useFormik } from 'formik'
import { TextInput } from '../../components/atoms'
import { IProfileFormInitialValue } from '../../types'
import { InitialValues } from './InitialValues'
import { getUser, updateUser } from '../../services/ApiClient'
import { toast } from 'react-hot-toast'
import ProfileFormValidation from '../../formik/validations/ProfileFormValidation'
import { useMutation, useQuery } from 'react-query'
import { useEffect } from 'react'
import { FileInput } from '../../components/atoms/FileInput'
import { setUser } from '../../redux/actions/LoginActions'
import { useDispatch } from 'react-redux'
import { signOut } from '../../lib/utils'
import { RiLogoutCircleLine } from 'react-icons/ri'

export const ProfileForm = () => {
  const { data: user, isLoading } = useQuery('getUser', () => getUser())
  const dispatch = useDispatch()

  const updateProfile = useMutation(
    (values: IProfileFormInitialValue) => updateUser(values),
    {
      onSuccess: (data) => {
        toast.success('Profile updated successfully')
        dispatch(setUser(data))
      },
      onError: () => {
        toast.error('Profile updating failed')
      },
    },
  )

  const handleSubmit = async (values: IProfileFormInitialValue) => {
    await updateProfile.mutate(values)
  }

  const formik = useFormik<IProfileFormInitialValue>({
    initialValues: InitialValues,
    validationSchema: ProfileFormValidation,
    onSubmit(values) {
      handleSubmit(values)
    },
  })

  const { values, errors } = formik

  useEffect(() => {
    if (user) {
      const data = {
        ...user,
        profile_picture: new File(['foo'], 'foo.txt', {
          type: 'text/plain',
        }),
      }
      formik.setValues(data)
    }
  }, [user])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className="min-h-screen my-auto md:mt-10">
      <form
        className="flex max-w-lg flex-col bg-white mx-auto shadow-xl rounded-lg px-10 py-5 gap-10"
        onSubmit={formik.handleSubmit}
        encType="multipart/form-data"
      >
        <h3 className="font-bold text-2xl mx-auto">Update your profile</h3>
        <div>
          <FileInput
            title="Please Upload your profile picture"
            name="profile_picture"
            placeholder="John"
            onChange={(event) => {
              const file = event.target.files && event.target.files[0]
              if (file) {
                formik.setFieldValue('profile_picture', file)
              }
            }}
          />
        </div>
        <div>
          <TextInput
            title="Please enter your first name"
            name="first_name"
            type="text"
            placeholder="John"
            shadow
            value={values.first_name}
            onChange={formik.handleChange}
            error={errors['first_name']}
          />
        </div>

        <div>
          <TextInput
            title="Please enter your last name"
            name="last_name"
            type="text"
            placeholder="John"
            shadow
            value={values.last_name}
            onChange={formik.handleChange}
            error={errors['last_name']}
          />
        </div>

        <div>
          <TextInput
            title="Your email"
            name="email"
            type="email"
            placeholder="John@example.com"
            shadow
            value={values.email}
            onChange={formik.handleChange}
            error={errors['email']}
            disabled
          />
          <span className="text-xs text-gray-600">
            please contact support to update the email
          </span>
        </div>

        <div>
          <TextInput
            title="Please enter your phone number"
            name="phone_number"
            type="phone_number"
            placeholder="+44 9999999999"
            shadow
            value={values.phone_number}
            onChange={formik.handleChange}
            error={errors['phone_number']}
          />
        </div>

        <Button type="submit" className="mx-auto font-bold">
          Update your profile
        </Button>
      </form>
      <div className='w-1/2 mx-auto md:hidden'>
        <Button
          className="w-full text-center mx-auto mt-10 mb-10 bg-gradient-to-br from-gray-800 to-green-800 text-white"
          onClick={() => {
            signOut()
          }}
          size={'sm'}
        >
          <span className="flex flex-row justify-center w-full items-center text-lg">
            <RiLogoutCircleLine className="mr-3" size={20} />
            Log Out
          </span>
        </Button>
      </div>
    </div>
  )
}
