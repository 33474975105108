import { IRegisterInitialValues } from '../../types'

export const InitialValues: IRegisterInitialValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  confirm_password: '',
  phone_number: '',
  remember: false,
}
