import React, { useState, FormEvent, ChangeEvent } from 'react'
import { FiSearch } from 'react-icons/fi'
import { MdSportsCricket } from 'react-icons/md'
import { TextInput } from 'flowbite-react'
import { customStyles } from '../../ui/styles'
import { ISearchBar } from '../../types'

const SearchBar: React.FC<ISearchBar> = ({ onChange = () => { } }) => {
  const [searchValue, setSearchValue] = useState<string>('')

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
    onChange(e.target.value)
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
  }

  return (
    <form className="flex items-center w-full" onSubmit={handleSubmit}>
      <label htmlFor="simple-search" className="sr-only">
        Search
      </label>
      <div className="relative w-full">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
        <TextInput
          type="text"
          id="simple-search"
          value={searchValue}
          onChange={handleInputChange}
          sizing="md"
          icon={() => {
            return (
              <MdSportsCricket
                style={customStyles}
                size={20}
                className="mr-3"
              />
            )
          }}
          rightIcon={() => {
            return <FiSearch style={customStyles} size={25} />
          }}
          shadow={true}
          style={{ background: 'white' }}
          className="mx-auto focus:bg-gradient-to-br focus:from-gray-800 focus:to-green-800"
          placeholder="Search for any sport or venue or place"
        />
      </div>
    </form>
  )
}

export default SearchBar
