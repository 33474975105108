import React from 'react'

import { Sidebar } from 'flowbite-react'
import { IconType } from 'react-icons'
import { Link } from 'react-router-dom'

interface ISideBarItemProps {
  icon: IconType
  text: string
  to?: string
  className?: string
  // TODO: Type this to tailwindcss type
}

const SideBarItem: React.FC<ISideBarItemProps> = ({
  icon,
  text,
  to = '#',
  className = '',
}: ISideBarItemProps) => {
  return (
    <Sidebar.Item as={Link} to={to} className={`group ${className} transition-colors duration-200 hover:bg-gradient-to-br from-gray-800 to-green-800 hover:text-white hover:bg-gradient-to-br hover:from-gray-800 hover:to-green-800`}>
      <div className="flex align-middle items-center font-semibold my-2">
        {icon({
          size: '25',
          className: 'mr-5 group-hover:text-white hover:bg-white text-green-800'
        })}
        <span className="group-hover:text-white text-green-800">{text}</span>
      </div>
    </Sidebar.Item>
  )
}

export default SideBarItem