import React from 'react'
import WhitePlayer from '../../assets/images/playasport-white.png'

const LeftPanel: React.FC = () => {
  return (
    <div className='flex flex-col items-center justify-between md:min-h-screen text-white pt-10 px-10 font-mono bg-gradient-to-r from-gray-800 to-green-800 p-10 transition-all duration-500 ease-in-out transform hover:scale-105'>
      <div className='mb-10'>
        <img src={WhitePlayer} alt='play a sort white logo' />
      </div>
      <div className='text-xl mb-40 tracking-widest text-center leading-10 font-semibold hidden md:block animate-fadeIn'>
        Discover your passion for sports with Playasport! Join activities seamlessly, connect with fellow enthusiasts, and enjoy features like messaging and easy payment management. From tennis to rugby, unlock a world of athletic engagement.
      </div>
    </div>
  );
};

export default LeftPanel;