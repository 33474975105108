import React, { ReactNode } from 'react'
import LeftPanel from './auth/LeftPanel'
interface ILayoutProps {
  children: ReactNode
}

export const PublicLayout: React.FC<ILayoutProps> = ({ children }) => {
  return (
    <div className="w-full p-0 b-0 flex flex-col md:flex-row flex-wrap">
      <aside className="md:h-screen md:m-0 top-0 bg-gradient-to-br from-gray-800 to-green-800 flex-1">
        <LeftPanel />
      </aside>
      <main className="flex-1 bg-gradient-to-r from-green-800 to-gray-800 sm:px-0 sm:py-0 md:px-4 md:py-5 sm:p-0 h-screen">{children}</main>
    </div >
  )
}
