import { Checkbox, Table } from 'flowbite-react';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonPaymentsTable: React.FC = () => {
  return (
    <div className="overflow-x-auto">
      <Table hoverable striped className={'border-green-800 border-1 rounded-lg shadow-xl'}>
        <Table.Head>
          <Table.HeadCell className="p-4">
            <Checkbox />
          </Table.HeadCell>
          <Table.HeadCell>
            <Skeleton width={150} />
          </Table.HeadCell>
          <Table.HeadCell>
            <Skeleton width={100} />
          </Table.HeadCell>
          <Table.HeadCell>
            <Skeleton width={100} />
          </Table.HeadCell>
          <Table.HeadCell>
            <Skeleton width={100} />
          </Table.HeadCell>
          <Table.HeadCell>
            <span className="sr-only">Edit</span>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {[1, 2, 3].map((index) => (
            <Table.Row key={index} className="bg-white">
              <Table.Cell className="p-4">
                <Checkbox />
              </Table.Cell>
              <Table.Cell>
                <Skeleton width={150} />
              </Table.Cell>
              <Table.Cell>
                <Skeleton width={100} />
              </Table.Cell>
              <Table.Cell>
                <Skeleton width={100} />
              </Table.Cell>
              <Table.Cell>
                <Skeleton width={100} />
              </Table.Cell>
              <Table.Cell>
                <Skeleton width={50} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default SkeletonPaymentsTable;
