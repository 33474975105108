import { Label, TextInput as FBTextInput } from 'flowbite-react'
import React, { useRef } from 'react';

interface OtpInputProps {
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  error?: string;
  className?: string;
  title: string;
  name: string;
}

export const OtpInput: React.FC<OtpInputProps> = ({ value, onChange, onBlur, error, className, title, name }) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newOtp = value.split('');
    newOtp[index] = e.target.value;
    onChange(newOtp.join(''));
    if (e.target.value && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  return (
    <div className={className}>
      <div className="mb-2 block">
        <Label htmlFor={name} value={title} className={className} />
      </div>
      <div className="flex gap-2">
        {Array(6)
          .fill('')
          .map((_, index) => (
            <FBTextInput
              key={index}
              type="text"
              maxLength={1}
              value={value[index] || ''}
              onChange={(e) => handleChange(e, index)}
              onBlur={onBlur}
              style={{ textAlign: 'center' }}
              ref={(el) => (inputRefs.current[index] = el)}
            />
          ))}
      </div>

      {error !== 'undefined' && (<p className='text-red-500 text-sm'>{error}</p>)}
    </div>
  );
};