import React from 'react'

import { useSelector } from 'react-redux'
import { selectUser } from '../../redux/selectors'

export interface IMessage {
  key: number,
  content: string
  name: string
  userId: number
}
export const Message: React.FC<IMessage> = ({ content, name, userId }) => {
  const currentUser = useSelector(selectUser)
  const customMessageStyle = {
    background: (currentUser.id === userId ? '#d9fdd3' : '#ffff')
  }

  return (
    <div
      className={
        'flex flex-row px-3 py-2' +
        (currentUser.id === userId ? ' justify-end' : ' justify-start')
      }
    >
      <div
        style={customMessageStyle}
        className="px-2 py-1 rounded-lg shadow-md"
      >
        <span className="text-green-800 font-semibold text-xs">
          ~&nbsp;{name}
        </span>
        <br />
        <span className="text-gray-700">{content}</span>
      </div>
    </div>
  )
}
