import React from 'react'

import {
  Datepicker as FBDateicker,
  Label,
} from 'flowbite-react'
import { IDatepickerProps } from '../../types'
export const DatePicker: React.FC<IDatepickerProps> = ({
  title,
  name,
  error,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange,
  handleDateChange,
  ...props
}) => {
  return (
    <>
      <div className="mb-2 block">
        <Label htmlFor={name} value={title} />
      </div>
      <FBDateicker
        title={title}
        name={name}
        showTodayButton={false}
        showClearButton={false}
        onSelectedDateChanged={(date) => {
          handleDateChange(date)
        }}
        {...props}
      />
      {error !== 'undefined' && (<p className='text-red-500 text-sm'>{error}</p>)}
    </>
  )
}
