import { Button, Select as FBSelect, Label } from 'flowbite-react'
import { TextInput } from '../../components/atoms/TextInput'
import { Select } from '../../components/atoms/Select'
import { DatePicker } from '../../components/atoms/DatePicker'
import { FaPoundSign } from 'react-icons/fa'
import { CheckBox } from '../../components/atoms/CheckBox'
import { ToolTip } from '../../components/atoms/ToolTip'
import { useFormik } from 'formik'
import { InitialValues } from '../../features/activity/InitialValues'
import { toast } from 'react-hot-toast'
import { customStyles } from '../../ui/styles'
import { ColourOption, IInitialValues } from '../../types'
import { createActivityValidationSchema } from '../../formik/validations/createActivityValidationSchema'
import { createActivity } from '../../services/ApiClient'
import { useNavigate } from 'react-router-dom'
import { getSportIcon } from '../../lib/utils'
import AutoComplete from '../../components/atoms/AutoComplete'
import { BsFillInfoCircleFill } from "react-icons/bs";

export const sportOptions: ColourOption[] = [
  { value: 'badminton', label: 'Badminton', icon: getSportIcon('badminton') },
  {
    value: 'basketball',
    label: 'Basketball',
    icon: getSportIcon('basketball'),
  },
  { value: 'cricket', label: 'Cricket', icon: getSportIcon('cricket') },
  { value: 'rugby', label: 'Rugby', icon: getSportIcon('rugby') },
  { value: 'tennis', label: 'Tennis', icon: getSportIcon('tennis') },
  { value: 'football', label: 'football', icon: getSportIcon('football') },
]

export const CreateActivityForm = () => {
  const navigate = useNavigate()

  const handleSubmit = async (values: IInitialValues) => {
    const response = await createActivity(values)
    if (response) {
      toast.success('Successfully create your game')
      navigate('/activities')
    }
  }
  const formik = useFormik<IInitialValues>({
    initialValues: InitialValues,
    validationSchema: createActivityValidationSchema,
    onSubmit(values) {
      handleSubmit(values)
    },
  })

  const { values, errors } = formik

  const hourOptions = Array.from({ length: 24 }, (_, i) =>
    String(i).padStart(2, '0'),
  )
  const minuteOptions = ['00', '30']

  const handleStartTimeChange = (event: {
    target: { name: string; value: string }
  }) => {
    const { name, value } = event.target
    formik.setFieldValue(name, value)

    const endTime = new Date(`2000-01-01T${value}`)
    endTime.setMinutes(endTime.getMinutes() + 30)
    const formattedEndTime = endTime.toTimeString().slice(0, 5)
    formik.setFieldValue('end_time', formattedEndTime)
  }

  const renderBankAccountFields = () => {
    if (values.pay_and_join) {
      return (
        <>
          <div className="mb-5">
            <TextInput
              title="Bank Full Name"
              name="bank_full_name"
              type="text"
              placeholder="John Doe"
              shadow
              value={values.bank_full_name}
              onChange={formik.handleChange}
              error={errors['bank_full_name']}
            />
          </div>
          <div className="mb-5">
            <TextInput
              title="Sort Code"
              name="sort_code"
              type="text"
              placeholder="12-34-56"
              shadow
              value={values.sort_code}
              onChange={formik.handleChange}
              error={errors['sort_code']}
            />
          </div>
          <div className="mb-5">
            <TextInput
              title="Account Number"
              name="account_number"
              type="text"
              placeholder="12345678"
              shadow
              value={values.account_number}
              onChange={formik.handleChange}
              error={errors['account_number']}
            />
          </div>
        </>
      )
    }
    return null
  }

  return (
    <form
      className="flex flex-wrap w-full md:w-5/6 flex-col gap-4 px-5 py-5 mx-auto my-0 min-h-screen lg:border-3 justify-center md:mt-5 animate-fadeIn"
      onSubmit={formik.handleSubmit}
    >
      <div className="flex flex-row w-full gap-2 flex-wrap animate-fadeIn  border-2 border-green-800">
        <div className="w-full md:flex-1 m-5 mx-auto lg:mx-10 lg:w-auto animate-fadeIn">
          <div className="mb-5">
            <Select
              title="Please select the sport type"
              name="sport_type"
              options={sportOptions}
              value={values.sport_type}
              onChange={(value) => {
                formik.setFieldValue('sport_type', value)
              }}
              error={errors['sport_type']}
            />
          </div>

          <div className="mb-5">
            <AutoComplete
              title="Please enter the venue name"
              name="venue_name"
              type="text"
              placeholder="National Badminton Center"
              value={values.venue_name}
              error={errors['venue_name']}
              onChange={formik.handleChange}
              className={''}
              setFieldValue={formik.setFieldValue}
            />
          </div>

          <div className="mb-5">
            <TextInput
              title="Please enter the number of participants"
              name="number_of_participants"
              type="number"
              placeholder="4"
              shadow
              value={values.number_of_participants}
              onChange={formik.handleChange}
              error={errors['number_of_participants']}
            />
          </div>

          <div className="mb-5">
            <TextInput
              title="Please enter the total price"
              name="total_price"
              type="number"
              placeholder="the price will be split once we have confirmed players"
              shadow
              icon={() => <FaPoundSign style={customStyles} />}
              value={values.total_price}
              onChange={formik.handleChange}
              error={errors['total_price']}
            />
          </div>

          <div className="flex flex-row items-center text-wrap mb-5 animate-fadeIn">
            <CheckBox
              title="Pay and Join ?"
              name="pay_and_join"
              checked={values.pay_and_join}
              onChange={formik.handleChange}
            />
            <ToolTip text="Players will be automatically added to the game after the payment.">
              <BsFillInfoCircleFill />
            </ToolTip>
          </div>
        </div>
        <div className="w-full md:flex-1 m-5 mx-auto lg:mx-10 lg:w-auto">
          <div className="mb-5">
            <DatePicker
              title="Please enter the date of the activity"
              name="activity_date"
              minDate={new Date()}
              defaultDate={values.activity_date}
              handleDateChange={(date) =>
                formik.setFieldValue('activity_date', date)
              }
              error={String(errors['activity_date'])}
              shadow
            />
          </div>

          <div className="mb-5">
            <div className="mb-2 block">
              <Label
                htmlFor={'start_time'}
                value={'Start time of the activity'}
              />
            </div>
            <FBSelect
              name="start_time"
              value={String(formik.values.start_time)}
              onChange={handleStartTimeChange}
              className="time-select"
            >
              {hourOptions.map((hour) =>
                minuteOptions.map((minute) => (
                  <option key={`${hour}:${minute}`} value={`${hour}:${minute}`}>
                    {`${hour}:${minute}`}
                  </option>
                )),
              )}
            </FBSelect>
          </div>

          <div className="mb-5">
            <div className="mb-2 block">
              <Label htmlFor={'end_time'} value={'End time of the activity'} />
            </div>
            <FBSelect
              name="end_time"
              value={String(formik.values.end_time)}
              onChange={handleStartTimeChange}
              className="time-select"
            >
              {hourOptions.map((hour) =>
                minuteOptions.map((minute) => (
                  <option key={`${hour}:${minute}`} value={`${hour}:${minute}`}>
                    {`${hour}:${minute}`}
                  </option>
                )),
              )}
            </FBSelect>
          </div>
          <div className="flex flex-row items-center text-wrap mb-5">
            <CheckBox
              title="Invites-only game"
              name="invites_only"
              checked={values.invites_only}
              onChange={formik.handleChange}
              error={errors['invites_only']}
            />
            <ToolTip
              text="This game will remain private to other users on the platform. 
                       Its visibility is limited to those with whom it has been shared. 
                       Therefore, it is advised to manage the link and exercise discretion when accessing it securely"
            >
              <BsFillInfoCircleFill />
            </ToolTip>
          </div>

          <div className="mb-5">
            <CheckBox
              title="Is the court booked?"
              name="court_booked"
              checked={values.court_booked}
              onChange={formik.handleChange}
              error={errors['court_booked']}
            />
          </div>

          <div className="mb-5">
            <CheckBox
              title="bring your own equipment ?"
              name="byoe"
              checked={values.byoe}
              onChange={formik.handleChange}
            />
          </div>

          {renderBankAccountFields()}
        </div>
      </div>

      <Button type="submit" className="mx-auto font-bold transition-transform hover:scale-110 hover:bg-green-600">
        Create My Game
      </Button>
    </form>
  )
}
