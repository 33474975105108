import * as Yup from 'yup'
import { IInitialValues } from '../../types'

const validActivityTypes = [
  'badminton',
  'basketball',
  'cricket',
  'rugby',
  'tennis',
  'football',
]

export const createActivityValidationSchema: Yup.Schema<IInitialValues> =
  Yup.object().shape({
    sport_type: Yup.string()
      .required('Please select a sport type')
      .oneOf(validActivityTypes, 'Invalid sport type selected'),
    venue_name: Yup.string().required('Please enter the venue name'),
    number_of_participants: Yup.number()
      .min(1, 'Number of participants must be greater than 0')
      .required('Please enter the number of participants'),
    activity_date: Yup.date().required('Please enter the date of the activity'),
    start_time: Yup.string().required('Please enter the start time'), // Updated to use Yup.date()
    end_time: Yup.string().required('Please enter the end time'),
    total_price: Yup.number().required('Please enter the total price'),
    court_booked: Yup.boolean().required(
      'Please specify if the court is booked',
    ),
    invites_only: Yup.boolean().required('Please specify if invites only'),
    byoe: Yup.boolean().required('Please specify if BYOE is allowed'),
    image_url: Yup.string().required('Please provide an image URL'),
    pay_and_join: Yup.boolean().required(
      'Please specify if pay and join is enabled',
    ),
    bank_full_name: Yup.string().when('pay_and_join', ([pay_and_join]) => {
      return pay_and_join
        ? Yup.string().required('Bank full name is required')
        : Yup.string()
    }),
    sort_code: Yup.string().when('pay_and_join', ([pay_and_join]) => {
      return pay_and_join
        ? Yup.string()
            .matches(/^\d{6}$/, 'Sort code must be 6 digits')
            .required('Sort code is required')
        : Yup.string()
    }),
    account_number: Yup.string().when('pay_and_join', ([pay_and_join]) => {
      return pay_and_join
        ? Yup.string()
            .matches(/^\d{8}$/, 'Account number must be 8 digits')
            .required(' Account number is required ')
        : Yup.string()
    }),
  })
