import { Spinner, TextInput } from 'flowbite-react'
import { IoSend } from 'react-icons/io5'
import { serverTimestamp } from 'firebase/firestore'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  addMessageToFirebase,
  fetchActivityChatCollection,
} from '../../services/firebase-functions'
import { Message } from '../../components/atoms/'
import { IMessageChatRequest, IParticipant } from '../../types'
import { FormEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../redux/selectors'
import { useParams } from 'react-router-dom'
import { getActivity } from '../../services/ApiClient'
import {
  formatDate,
  formatTime,
  getSportIcon,
  hostProfilePicUrl,
} from '../../lib/utils'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { MdOutlineCalendarMonth } from 'react-icons/md'
import React from 'react'

export const GroupChat: React.FC = () => {
  const { id } = useParams()
  const queryClient = useQueryClient()
  const currentUser = useSelector(selectUser)
  const [content, setContent] = useState<string>('')
  const { data: activity, isLoading: isActivityLoading } = useQuery(
    'getActivity',
    () => getActivity(Number(id)),
  )

  const chatMutation = useMutation(
    (data: IMessageChatRequest) => addMessageToFirebase(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('fetchActivityChatCollection')
        setContent('')
      },
    },
  )

  const { data: messages } = useQuery(
    'fetchActivityChatCollection',
    () => fetchActivityChatCollection(Number(id)),
  )

  const handleMessageChange = (input: string) => {
    setContent(input)
  }

  const handleSendMessage = (e: FormEvent) => {
    e.preventDefault()
    chatMutation.mutate({
      activityId: Number(id),
      userId: currentUser.id,
      messager_name: currentUser.first_name + ' ' + currentUser.last_name,
      content: content,
      timestamp: serverTimestamp(),
    })
    scrollToBottom()
  }

  if (isActivityLoading) {
    return <Spinner />
  }

  const renderParticipantsName = (): string => {
    if (activity && activity.participants && activity.participants.length > 0) {
      const participants = activity.participants.map(
        (participant: IParticipant) => {
          return participant.participant_name
        },
      )
      return participants.join(', ')
    }
    return "";
  }

  const scrollToBottom = () => {
    const chatContainer = document.getElementById('your-chat-container-id') // Replace with the actual ID of your chat container
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight
    }
  }
  if (!activity) {
    return null;
  }

  return (
    <div
      className="rounded-md h-screen overflow-y-auto"
      style={{ background: '#efeae2' }}
    >
      <div className="flex flex-col rounded-md">
        <div className="flex flex-col">
          <div className="bg-green-100 px-3 py-2 rounded-t-md border-gray-200 border">
            {/* TOP PART */}
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center">
                <img
                  alt="Bonnie"
                  src={hostProfilePicUrl(activity.participants)}
                  className="rounded-full shadow-md mr-2"
                  width={40}
                  height={40}
                />
                <div className="items-center justify-between gap-5 text-green-800 flex-wrap grid lg:flex lg:flex-row">
                  <div className="flex-1">
                    {activity.user_first_name}'s activity
                    <p className="capitalize text-xs text-gray-600">
                      {renderParticipantsName()}
                    </p>
                  </div>
                  <a href={activity.google_map_url} target="blank" className='underline text-blue-600 md:text-center'>
                    <div className="flex flex-2 flex-row items-center capitalize flex-wrap">
                      <FaMapMarkerAlt className="mr-2" />
                      {activity.venue_name?.split(',')[0]}
                    </div>
                  </a>

                  <div className="flex flex-1 flex-row items-center capitalize flex-wrap">
                    {getSportIcon('badminton')} {'badminton'}
                  </div>
                  <div className="flex flex-2 flex-row items-center capitalize flex-wrap">
                    <MdOutlineCalendarMonth className="mr-2" />
                    {formatDate(activity.activity_date)} &#x2022;{' '}
                    {formatTime(activity.start_time, activity.end_time)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="text-gray-800 text-sm h-[70vh] md:h-[82vh]"
            id="your-chat-container-id"
          >
            {messages &&
              messages.length > 0 &&
              messages.map((message, index) => {
                return (
                  <Message
                    key={index}
                    content={message.content}
                    name={message.messager_name}
                    userId={message.userId}
                  />
                )
              })}
          </div>
        </div>
        <form
          onSubmit={(e) => {
            handleSendMessage(e)
          }}
        >
          <div className="flex flex-row items-center justify-between bg-slate-100 p-3 rounded-t-md border-gray-20 fixed bottom-[54px] w-full md:relative md:bottom-0">
            <div className="flex flex-row items-center flex-1">
              <TextInput
                placeholder="Type a message"
                className="w-full mr-5"
                value={content}
                onChange={(e) => handleMessageChange(e.currentTarget.value)}
              />
            </div>
            <div
              className="cursor-pointer"
              onClick={(e) => {
                handleSendMessage(e)
              }}
            >
              <IoSend className="text-green-800 mr-5" size={25} />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
