import React, { useState, useEffect } from 'react';
import { Button } from 'flowbite-react';
const CookiePopUp = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'false');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-5 left-5 right-5 bg-white border border-gray-300 p-3 shadow-lg flex flex-col items-center z-50 rounded">
      <p className="mb-4 text-center">
        We use cookies to ensure you get the best experience on our website.
        Please accept cookies for optimal performance.
      </p>
      <div className="flex space-x-4">
        <Button
          onClick={handleAccept}
          gradientDuoTone={'cyanToBlue'}
        >
          Accept
        </Button>
        <Button
          onClick={handleDecline}
          gradientDuoTone={'cyanToBlue'}
        >
          Decline
        </Button>
      </div>
    </div>
  );
};

export default CookiePopUp;
