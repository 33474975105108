import SkeletonGroupChatCard from '../../features/chat/SkeletonGroupChatCard'
import { getUserActivities } from '../../services/ApiClient'
import { useQuery } from 'react-query'
import { GroupChatCard } from '../../features/chat/GroupChatCard'
import { IActivityResponse } from '../../types'
import { Tabs } from 'flowbite-react'
import { MdDashboard } from 'react-icons/md'
import { HiUserCircle } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { selectUser } from '../../redux/selectors'

export const Messages = () => {
  const currentUser = useSelector(selectUser)

  const { data, isLoading } = useQuery('fetchActivities', () =>
    getUserActivities(),
  )

  if (isLoading) {
    return (
      <div className="flex flex-row flex-wrap w-full">
        {[...Array(10)].map((_, index) => (
          <SkeletonGroupChatCard key={index} />
        ))}
      </div>
    )
  }

  const hostedActivities = data?.activities.filter((activity) =>
    activity.participants?.some(
      (participant) =>
        participant.user_id === currentUser?.id && participant.is_owner,
    ),
  );

  const participatingActivities = data?.activities.filter((activity) =>
    activity.participants?.some(
      (participant) =>
        participant.user_id === currentUser?.id && !participant.is_owner,
    ),
  );

  const active = () => {
    if (participatingActivities && participatingActivities?.length > 0) {
      return "participating"
    } else {
      return "hosted"
    }
  }

  return (
    <>
      <Tabs aria-label="Full width tabs" style="underline">
        <Tabs.Item active={active() === 'participating'} title="Participating Activities" icon={MdDashboard}>
          <div className="flex flex-col flex-wrap mt-5 justify-evenly gap-5 px-10">
            {participatingActivities &&
              participatingActivities.length > 0 &&
              participatingActivities.map((activity: IActivityResponse) => {
                return (
                  <GroupChatCard
                    venueName={activity.venue_name}
                    imageUrl={activity.image_url}
                    sportType={activity.sport_type}
                    startTime={activity.start_time}
                    endTime={activity.end_time}
                    activityDate={activity.activity_date}
                    createdName={activity.user_first_name}
                    userId={activity.user_id}
                    id={activity.id}
                    activityRequests={activity.activity_requests}
                    pendingReqeuests={activity.pending_requests}
                    participants={activity.participants}
                    payAndJoin={activity.pay_and_join}
                    googleMapUrl={activity.google_map_url}
                  />
                )
              })}
          </div>
        </Tabs.Item>
        <Tabs.Item active={active() === 'hosted'} title="Hosted activities" icon={HiUserCircle}>
          <div className="flex flex-col flex-wrap mt-5 mx-auto justify-evenly gap-5 px-10">
            {hostedActivities &&
              hostedActivities.length > 0 &&
              hostedActivities.map((activity: IActivityResponse) => {
                return (
                  <GroupChatCard
                    venueName={activity.venue_name}
                    imageUrl={activity.image_url}
                    sportType={activity.sport_type}
                    startTime={activity.start_time}
                    endTime={activity.end_time}
                    activityDate={activity.activity_date}
                    createdName={activity.user_first_name}
                    userId={activity.user_id}
                    id={activity.id}
                    activityRequests={activity.activity_requests}
                    pendingReqeuests={activity.pending_requests}
                    participants={activity.participants}
                    payAndJoin={activity.pay_and_join}
                    googleMapUrl={activity.google_map_url}
                  />
                )
              })}
          </div>
        </Tabs.Item>
      </Tabs>
    </>
  )
}
