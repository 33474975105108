import * as yup from 'yup';

export const ProfileFormValidation = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email format'),
  phone_number: yup.string().matches(/^\d{1,10}$/, 'Invalid phone number').required('Phone number is required'),
});

export default ProfileFormValidation;