import { useRef, useEffect } from 'react'
import { Label, TextInput as FBTextInput } from 'flowbite-react'
import PropTypes from 'prop-types'

const AutoComplete = ({
  title,
  name,
  type,
  placeholder,
  error,
  className,
  setFieldValue,
  ...props
}) => {
  const autoCompleteRef = useRef()
  const inputRef = useRef()
  const options = {
    componentRestrictions: { country: 'GB' },
  }

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options,
    )
    autoCompleteRef.current.addListener('place_changed', async function () {
      const place = await autoCompleteRef.current.getPlace()
      setFieldValue(name, place.name + ', ' + place.formatted_address)
      setFieldValue('google_map_url', place.url)
    })
  }, [])


  return (
    <div className={className}>
      <div className="mb-2 block">
        <Label htmlFor={name} value={title} className={className} />
      </div>
      <FBTextInput
        id={name}
        type={type}
        placeholder={placeholder}
        className={className}
        ref={inputRef}
        {...props}
      />
      {error !== 'undefined' && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  )
}

AutoComplete.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  error: PropTypes.string,
  className: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired
}

export default AutoComplete
