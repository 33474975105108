import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'

import { Button, Card, Spinner } from 'flowbite-react'
import { TextInput, CheckBox } from '../../components/atoms'
// import { FcSportsMode } from 'react-icons/fc'
import { IoLogIn, IoPersonAdd } from 'react-icons/io5'
import { validationSchema } from '../../formik/validations/LoginValidationSchema'
import { LoginInitialValues } from '../../features/login/InitialValues'
import {
  getUser,
  handleLogin,
  signInWithGoogle,
} from '../../services/ApiClient'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { IAxiosError, ILoginInitialValues, IUserResponse } from '../../types'
import { setUser } from '../../redux/actions/LoginActions'
import { GoogleLogin } from '@react-oauth/google'
import { toast } from 'react-hot-toast'

export const LoginForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const formik = useFormik({
    initialValues: LoginInitialValues,
    validationSchema: validationSchema,
    onSubmit: async (values: ILoginInitialValues) => {
      await loginMutation.mutateAsync(values)
    },
  })

  useMutation('getUser', () => getUser(), {
    onSuccess: (data: IUserResponse) => {
      dispatch(setUser(data))
    },
  })

  const loginMutation = useMutation(
    async (values: ILoginInitialValues) => await handleLogin(values),
    {
      onSuccess: (response) => {
        const { user } = response;

        if (user?.phone_number && !user?.phone_number_verified) {
          navigate('/otp-verification', { state: { phoneNumber: user.phone_number } });
        } else if (user?.phone_number_verified || !user?.phone_number) {
          queryClient.invalidateQueries('getUser');
          navigate('/');
        }
      },
      onError: (error: IAxiosError) => {
        toast.error(error.response.data.message)
      },
    },
  )

  return (
    <div className="flex flex-col md:items-center md:justify-center md:h-full">
      <Card className="p-3 w-full md:w-4/5 sm:w-full rounded-none border-none shadow-none md:shadow md:border md:rounded">
        {/* <FcSportsMode size={100} className="mx-auto" /> */}
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col gap-6 w-full"
        >
          <div>
            <TextInput
              title="Please enter your email"
              name="email"
              type="email"
              placeholder="user@gmail.com"
              className="text-md"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.errors.email}
            />
          </div>
          <div>
            <TextInput
              title="Please enter your password"
              name="password"
              type="password"
              className="text-md"
              placeholder="********************"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.errors.password}
            />
          </div>


          <div className="mx-auto">
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                if (credentialResponse && credentialResponse.credential) {
                  signInWithGoogle(credentialResponse.credential).then(
                    (data) => {
                      dispatch(setUser(data.user))
                      queryClient.invalidateQueries('getUser')
                      navigate('/')
                    },
                  )
                }
              }}
              onError={() => {
                toast.error('Login failed with google')
              }}
              useOneTap={true}
              auto_select={false}
              text={'continue_with'}
              shape={'circle'}
            />
          </div>

          <div className="flex items-center gap-2">
            <CheckBox
              title="I agree to "
              name="terms"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={formik.values.terms}
            />
            <Link to={"https://playasport.co.uk/terms-and-conditions"} className='text-blue-500 underline' target="_blank">
              terms and conditions
            </Link>
          </div>
          <div className="flex items-center gap-2">
            <CheckBox
              title="Remember Me"
              name="remember"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={formik.values.remember}
            />
          </div>

          <Button
            type="submit"
            className="text-center mx-auto w-full px-2 border flex hover:shadow transition duration-150 text-white flex-row items-center bg-gradient-to-tl from-gray-800 to-green-800"
            disabled={loginMutation.isLoading}
          >
            {loginMutation.isLoading ? (
              <>
                <Spinner aria-label="Spinner button example" size="sm" />
                <span className="pl-3">Loading...</span>
              </>
            ) : (
              <div className="flex flex-row items-center">
                <IoLogIn className="mr-2" size={30} /> Login
              </div>
            )}
          </Button>

          <Button
            className="px-3 py-0 border flex hover:shadow transition duration-150 text-white  bg-gradient-to-br from-gray-800 to-green-800"
            as={Link}
            to={'/register'}
          >
            <div className="flex flex-row items-center">
              <IoPersonAdd className="mr-2" size={30} />
              <span>Create your account</span>
            </div>
          </Button>
        </form>
      </Card>
    </div>
  )
}
