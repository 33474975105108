import { Routes, Route } from 'react-router-dom'
import HomePage from '../pages/home-page'
import { Layout } from '../pages/layout'
import { Messages } from '../pages/messages'
import { Payments } from '../pages/payments'
import { Profile } from '../pages/users/Profile'
import { MyActivitesPage } from '../pages/activity'
import { CreateActivityFormPage } from '../pages/activity/createActivityForm'
import { PublicLayout } from '../pages/PublicLayout'
import LoginPage from '../pages/auth/login'
import { RegisterPage } from '../pages/auth/RegisterPage'
import { ActivityDetail } from '../features/activity/ActivityDetail'
import { GroupChat } from '../features/chat/GroupChat'
import React from 'react'
import { PaymentStatus } from '../pages/payments/PaymentStatus'
import { OtpVerificationPage } from '../pages/auth/OtpVerificationPage'

export const AppRoutes: React.FC = () => {

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <HomePage />
          </Layout>
        }
      />

      <Route
        path="/messages"
        element={
          <Layout>
            <Messages />
          </Layout>
        }
      />
      <Route
        path="/messages/activity_chat/:id"
        element={
          <Layout>
            <GroupChat />
          </Layout>
        }
      />
      <Route
        path="/activities"
        element={
          <Layout>
            <MyActivitesPage />
          </Layout>
        }
      />
      <Route
        path="/activity/create"
        element={
          <Layout>
            <CreateActivityFormPage />
          </Layout>
        }
      />
      <Route
        path="/payments"
        element={
          <Layout>
            <Payments />
          </Layout>
        }
      />
      <Route
        path="/my-profile"
        element={
          <Layout>
            <Profile />
          </Layout>
        }
      />

      <Route
        path="/activities/:id"
        element={
          <Layout>
            <ActivityDetail />
          </Layout>
        }
      />
      <Route
        path="/payment-status"
        element={
          <Layout>
            <PaymentStatus />
          </Layout>
        }
      />

      <Route
        path="/login"
        element={
          <PublicLayout>
            <LoginPage />
          </PublicLayout>
        }
      />
      <Route
        path="/register"
        element={
          <PublicLayout>
            <RegisterPage />
          </PublicLayout>
        }
      />
      <Route
        path="/otp-verification"
        element={
          <PublicLayout>
            <OtpVerificationPage />
          </PublicLayout>
        }
      />
    </Routes>
  )
}
