import React, { useEffect } from 'react'
import { Button, Sidebar } from 'flowbite-react'
import PlayASportLogo from '../../../assets/images/playsport-logo.png'
import { useNavigate } from 'react-router-dom'

import {
  HiOutlineFire,
  HiOutlineUserCircle,
} from 'react-icons/hi'

import { MdSportsCricket } from 'react-icons/md';
import { BiSolidTennisBall } from 'react-icons/bi'
import { FaWhatsapp, FaPoundSign } from 'react-icons/fa'
import { RiLogoutCircleLine } from 'react-icons/ri'

import { ProfileCard } from '../../compounds/profileCard'
import SideBarItem from '../../atoms/side-bar/SideBarItem'
import { getAccessToken, signOut } from '../../../lib/utils'

const SideBar: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!getAccessToken()) {
      navigate('/login')
    }
  }, [])

  return (
    <Sidebar aria-label="Side bar with menu">
      <Sidebar.Logo href="#" img={PlayASportLogo} imgAlt="Play a sport logo" />
      <Sidebar.Items>
        <Sidebar.ItemGroup>
          <SideBarItem icon={BiSolidTennisBall} text="Let's play" to="/" />
          <SideBarItem
            icon={MdSportsCricket}
            text="Create Game"
            to="/activity/create"
          />
          <SideBarItem
            icon={HiOutlineFire}
            text="My Games"
            to="/activities"
          />
          <SideBarItem icon={FaWhatsapp} text="Messages" to="/messages" />
          <SideBarItem
            icon={FaPoundSign}
            text="Payments"
            to="/payments"
          />
          <SideBarItem
            icon={HiOutlineUserCircle}
            text="My Profile"
            to="/my-profile"
          />

        </Sidebar.ItemGroup>
        <div className='absolute bottom-0 w-full px-2'>
          <ProfileCard />
          <Button
            className="w-full text-center mx-auto mt-10 mb-2 bg-gradient-to-br from-gray-800 to-green-800 text-white hover:text-white"
            onClick={() => {
              signOut()
            }}
          >
            <span className="flex flex-row justify-center w-full font-extrabold items-center">
              <RiLogoutCircleLine className="mr-3 h-4 w-4" size={30} />
              Log Out
            </span>
          </Button>
        </div>
      </Sidebar.Items>
    </Sidebar>
  )
}

export default SideBar
