import React from 'react';
import { Button } from 'flowbite-react';
import { RiSecurePaymentFill } from 'react-icons/ri';

interface IPayNowButton {
  onClick?: () => void;
  label?: string;
  className?: string;
  size?: string;
}

export const PayNowButton: React.FC<IPayNowButton> = ({
  onClick,
  label = 'PAY NOW',
  className = 'w-full text-center mx-auto',
  size = 'md',
}: IPayNowButton) => {
  return (
    <Button
      color="gray"
      gradientDuoTone={'greenToBlue'}
      className={className}
      onClick={onClick} // changed to onClick
      size={size}
    >
      <span className="flex flex-row justify-center w-full items-center">
        <RiSecurePaymentFill className="mr-2" size={25} />
        {label}
      </span>
    </Button>
  );
};
