import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonActivityCard: React.FC = () => {
  return (
    <div className="rounded-md bg-white shadow-md hover:shadow-xl mx-3 my-5" style={{ width: '255px' }}>
      <Skeleton height={200} width={'100%'} style={{ borderRadius: '8px 8px 0 0' }} />
      <div className="flex flex-col justify-between">
        <div className="px-3 py-3">
          <Skeleton height={20} width={'80%'} />
          <Skeleton height={20} width={'100%'} style={{ marginTop: '8px' }} />
          <Skeleton height={15} width={'50%'} style={{ marginTop: '12px' }} />
        </div>
        <div className="bg-gray-200 px-3 py-2 rounded-b-md">
          <Skeleton height={20} width={'30%'} style={{ marginRight: '4px' }} />
          <Skeleton height={20} width={'30%'} style={{ marginRight: '4px' }} />
          <Skeleton height={20} width={'30%'} />
        </div>
      </div>
    </div>
  );
};

export default SkeletonActivityCard;
